import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme// 
import "primereact/resources/primereact.min.css";                        //icons
import "primeicons/primeicons.css";    
import 'primeflex/primeflex.css';   
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

