import  Erro401 from '../../assets/error-401.png'; 
import "./style.css";

function NaoAutorizado() {
  return (
    <div className='card flex flex-wrap align-items-center justify-content-center container-auth'>
      <div id="div-nao-autorizado">
        <img src={Erro401} alt="Ícone de erro"></img>
        <div className="fields">
          <h1>401 - Não autorizado</h1>
          <h2>Sua sessão expirou. Entre novamente pelo painel do odin</h2>
          <a className='p-button p-component btn-link' href={process.env.REACT_APP_PAINEL_ODIN}>Painel Odin</a>
        </div>     
      </div>  
    </div>
  );
}

export default NaoAutorizado;