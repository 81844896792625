const TraductionSnapshotTitles = (item) => {
  const titles = {
    assetId: "ID",
    assetName: "Nome",
    status: "Status",
    size: "Tamanho (GB)",
    createdAt: "Criação",
    updatedAt: "Atualização",
  };

  return titles[item];
};

const TraductionVolumeTitles = (item) => {
  const titles = {
    assetId: "ID",
    assetName: "Nome",
    disk_type: "Tipo",
    status: "Status",
    disk_size: "Tamanho (GB)",
    imagem: "Imagem",
  };

  return titles[item];
};

export { TraductionSnapshotTitles, TraductionVolumeTitles };
