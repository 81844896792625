import Util from "../utils/Util";
import util from "../utils/Util";
import {api} from "./api";

class BillingSummaryService {
  async findConsumoGraficoPrincipal(customerId, startDate, endDate, regionId) {
    var retorno = ""; 
    try {
      var uri = "/cloudplus/billing/summary/data-dashboard"
       + "?startDate=" + startDate 
       + "&&endDate=" + endDate 
       + "&&customerId=" + customerId
        
      if (!util.isEmptyNumber(regionId) && regionId !== 0)
        uri += "&&regionId=" + regionId;
      
      retorno = await api.get(uri,Util.getTokenAPIBackEnd());
      return retorno.data;
    } catch (error) {
      return [];
    }
  }
}

export default new BillingSummaryService();