import {sensedia} from './services/api'
import encryptData from './utils/EncryptData'

const grantCodeData = {
  "client_id": process.env.REACT_APP_CLIENT_ID,
  "extraInfo": {},
  "redirect_uri": "http://localhost",
  "state": "string"
}

const getSensediaCode = (url) => {
  let url_string = url,
      newUrl = new URL(url_string),
      code = newUrl.searchParams.get('code')
  return code
}

export async function getGrantCode(){
  try {
    const response = await sensedia.post('/oauth/grant-code', grantCodeData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const sensediaCode = getSensediaCode(response.data.redirect_uri)
    localStorage.setItem('sensedia_code', sensediaCode)
    return await getAccessToken(sensediaCode)
  } catch (error) {

    return null;
  }
}

async function getAccessToken(sensediaCode) {
  const response = await sensedia.post('/oauth/access-token?grant_type=authorization_code&code=' + sensediaCode, null, {
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_SENSEDIA_AUTHORIZATION}`
    },
  });
  localStorage.setItem('access_token', response.data.access_token)
  localStorage.setItem('refresh_token', response.data.refresh_token)
  return response.data.access_token;
}

const getUserLogin = () => (
  {
    'sid': localStorage.getItem('session_id'),
    'uid': localStorage.getItem('uid')
  }
)

export async function isAuthenticated(){
  const params = getUserLogin()

  return await sensedia.post('/telecom/corporate/odin-multcloud/v1/validate/sessions', params, {headers: {
      "access_token": localStorage.getItem('access_token'),
      "client_id": process.env.REACT_APP_CLIENT_ID,
    }
  });
}

export function setDataExpiracao(data) {
  localStorage.setItem('session_expires', encryptData.encrypt(data.toISOString()))
}

export function getDataExpiracao() {
  return encryptData.decrypt(localStorage.getItem('session_expires'));
}

export function isSessionExpired() {
  var dataExpiracaoStr = getDataExpiracao();
  if (dataExpiracaoStr === undefined || dataExpiracaoStr === null)
    return true;
  
  var dataExpiracao = new Date(dataExpiracaoStr);
  var dataAtual = new Date();
  return dataExpiracao < dataAtual;
}

export function logout() {
  localStorage.removeItem("client_id");
  localStorage.removeItem("session_id");
  localStorage.removeItem("client_name");
  localStorage.removeItem("session_expires");
  getDataExpiracao();
  window.location = process.env.REACT_APP_PAINEL_ODIN;
  
}


