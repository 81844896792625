import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout";
import Dashboard from "./pages/Dashboard";
import ConsumoGeral from "./pages/ConsumoGeral";
import Instancias from "./pages/Instancias";
import Configuracoes from "./pages/Configuracoes";
import Login from "./pages/Login";
import { isSessionExpired } from "./auth";
import NaoAutorizado from "./Components/NaoAutorizado";
import NotFound from "./Components/NotFound";
import Error from "./assets/error-401.png";
import Footer from "./Components/Footer";
import Notifications from "./Components/Notifications";

function PrivateRoute({ children }) {
  return !isSessionExpired() ? children : <NaoAutorizado />;
}

export default function RoutesApp() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Layout>
                  <Notifications />
                  <Dashboard />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/consumo"
            element={
              <PrivateRoute>
                <Layout>
                  <Notifications />
                  <ConsumoGeral />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/instancias"
            element={
              <PrivateRoute>
                <Layout>
                  <Notifications />
                  <Instancias />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/configuracoes"
            element={
              <PrivateRoute>
                <Layout>
                  <Configuracoes />
                </Layout>
              </PrivateRoute>
            }
          />

          <Route path="/login" element={<Login />} />
          <Route
            path="*"
            element={
              <NotFound
                msgPrimary="404 - Página não encontrada"
                msgSecundary="Volte a página inicial"
                errorIcon={Error}
              />
            }
          />
        </Routes>
        {<Footer />}
      </BrowserRouter>
    </>
  );
}
