import React, { useState } from 'react';
import 'primeflex/primeflex.css';
import "./style.css";
import Sidebar from "../Sidebar"
import useWindowDimensions from '../../utils/DimensionsUtil';

function Layout({children}) {
  
  const dimensionWidthStartCollapsed = 1024
  const dimensions = useWindowDimensions();
  const [navVisible, showNavbar] = useState(dimensions.width === dimensionWidthStartCollapsed ? false : true);

  return ( 
    <div style={{ width: '100%', height:'100%' }}>
      <div className={showNavbar ? 'sidebar-expanded sidebar' : ''} id="sidebar">
          <Sidebar visible={ navVisible } show={ showNavbar } dimensions={dimensions} />
      </div>
      <div  className={ navVisible ? 'mainContent-expanded transitionEffects' : 'mainContent transitionEffects' } >{children}</div>
    </div>
  );

}

export default Layout;
