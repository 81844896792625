import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import './styles.css'

function MessageNotification(props) {

  function Icon(currentIcon = 'default') {
    const icons = {
      warning: 'pi-chart-line',
      info: 'pi-info-circle',
      error: 'pi-times',
      success: 'pi-check',
      default: 'pi-question'
    }

    return icons[currentIcon]
  }

  return (
    <>
      <div className={`card msg-notifications ${props.severity}`} key={props.keyId} >
        <Accordion activeIndex={1} onTabOpen={props.onTabOpen}  >
          <AccordionTab header={
            <div className="flex align-items-center">
              <i className={`pi ${Icon(props.severity)} mr-2`}></i>
              <span className="vertical-align-middle">{props.title}</span>
            </div>
          }>
            <>{props.children}</>
          </AccordionTab>
        </Accordion>
      </div>
    </>
  );

}

export default MessageNotification
