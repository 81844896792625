import { React, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Card } from "primereact/card";
import { Tooltip } from "primereact/tooltip";
import { logout } from "../../auth";
import Dialog from "../Dialog";
import AvatarUserWhite from "../../assets/Images/user-avatar-white.png";
import Avatar from "../../assets/Images/user.png";
import AvatarUserGreen from "../../assets/Images/user-avatar.png";
import AlgarCloudPlus from "../../assets/Images/algar-cloudplus.png";

import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import "./styles.css";
import "primeicons/primeicons.css";

const customClientName = (clientName) => {
  return clientName != null
    ? clientName
        .toLowerCase()
        .split(" ")
        .splice(0, 2)
        .map((t) => t.charAt(0).toUpperCase() + t.slice(1))
        .join(" ")
    : "";
};

const DialogLogout = ({ ...props }) => {
  return (
    <Dialog
      visible={props.visibleDialog}
      accept={() => logout()}
      message="Realmente deseja sair ?"
      header="Logout"
      icon="pi pi-exclamation-triangle"
    />
  );
};

let items = [
  {
    label: "Catálogo",
    icon: "pi pi-fw pi-file-pdf",
    command: () =>
      window.open("/pdf/CloudPlus_Catalogo_2023rev02.pdf", "_blank"),
  },
  {
    label: "Manual do Usuário",
    icon: "pi pi-fw pi-file-pdf",
    command: () =>
      window.open("/pdf/Manual_Usuario_Dasboard_CloudPlus_V2.pdf", "_blank"),
  },
];

const SidebarCollapsed = ({ ...props }) => {
  const menuLeft = useRef(null);
  return (
    <div className="nav-collapsed">
      <Tooltip target=".tooltip" mouseTrackLeft={10} />
      <nav>
        <button
          type="button"
          className="nav-btn"
          onClick={() => props.show(!props.visible)}
        >
          {!props.visible ? (
            <i className="pi pi-angle-right" />
          ) : (
            <i className="pi pi-angle-left" />
          )}
        </button>
        <div className="links">
          <NavLink
            to="/dashboard"
            className="nav-link link-hover tooltip"
            data-pr-tooltip="Dashboard"
            data-pr-position="right"
          >
            <i className="pi pi-microsoft" />
          </NavLink>
          <NavLink
            to="/consumo"
            className="nav-link tooltip"
            data-pr-tooltip="Consumo geral"
            data-pr-position="right"
          >
            <i className="pi pi-table" />
          </NavLink>
          <NavLink
            to="/instancias"
            className="nav-link tooltip"
            data-pr-tooltip="Instâncias"
            data-pr-position="right"
          >
            <i className="pi pi-server" />
          </NavLink>

          <a className="menudocuments_collapsed">
            <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
            <Button
              icon="pi pi-book"
              onClick={(event) => menuLeft.current.toggle(event)}
              aria-controls="popup_menu_left"
              aria-haspopup
            />
          </a>
        </div>

        <div className="nav-bottom">
          <div
            className="nav-bottom-user tooltip"
            data-pr-tooltip={props.clientName + "/" + props.clientID}
            data-pr-position="right"
          >
            <img
              style={{ width: "33px" }}
              alt="Icon user branco"
              src={AvatarUserWhite}
            />
          </div>
          <div className="links-bottom">
            <NavLink
              to="/configuracoes"
              className="nav-link tooltip"
              data-pr-tooltip="Configurações"
              data-pr-position="right"
            >
              <i className="pi pi-cog" />
            </NavLink>
            <a
              onClick={() => props.setVisibleDialog()}
              href="#"
              className="nav-link"
            >
              <i className="pi pi-sign-out" />
              <DialogLogout visibleDialog={props.visibleDialog} />
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

function Navbar({ visible, show, dimensions }) {
  const { width } = dimensions;
  const [visibleDialog, setVisibleDialog] = useState(false);
  const dimensionMinWidthCollapsed = 999;
  const clientName = window.localStorage.getItem("client_name");
  const clientID = window.localStorage.getItem("client_id");

  const menuLeft = useRef(null);

  return (
    <>
      <div className="mobile-nav">
        <button className="mobile-nav-btn" onClick={() => show(!visible)}>
          <i
            className={visible ? "btn-cg pi pi-bars" : "pi pi-bars"}
            style={{ fontSize: "1.5em" }}
          ></i>
        </button>
      </div>
      {!visible && width > dimensionMinWidthCollapsed ? (
        <SidebarCollapsed
          visible={visible}
          show={show}
          clientName={clientName}
          clientID={clientID}
          visibleDialog={visibleDialog}
          setVisibleDialog={() => setVisibleDialog(!visibleDialog)}
        />
      ) : (
        <nav className={!visible ? "navbar" : ""}>
          <Tooltip target=".tooltip" mouseTrackLeft={10} />
          <button
            type="button"
            className="nav-btn"
            onClick={() => show(!visible)}
          >
            {!visible ? (
              <i className="pi pi-angle-right" />
            ) : (
              <i className="pi pi-angle-left" />
            )}
          </button>
          <div>
            <NavLink className="logo" to="/">
              <img
                src={AlgarCloudPlus}
                alt="logo em branco da algar e cloud plus separados por uma linha na vertical"
              />
            </NavLink>
            <div className="links nav-top">
              <NavLink to="/dashboard" className="nav-link">
                <i className="pi pi-microsoft" />
                <span>Dashboard</span>
              </NavLink>
              <NavLink to="/consumo" className="nav-link">
                <i className="pi pi-table" />
                <span>Consumo geral</span>
              </NavLink>
              <NavLink to="/instancias" className="nav-link">
                <i className="pi pi-server" />
                <span>Instâncias</span>
              </NavLink>
              <a
                href={
                  process.env.REACT_APP_SIMULATOR +
                  "login?source=dashboard&clientId=" +
                  clientID
                }
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link"
              >
                <i className="pi pi-box" />
                <span>Simulador</span>
              </a>

              <a className="menudocuments">
                <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
                <Button
                  label="Documentos"
                  icon="pi pi-book"
                  onClick={(event) => menuLeft.current.toggle(event)}
                  aria-controls="popup_menu_left"
                  aria-haspopup
                />
              </a>
            </div>
          </div>
          <div className="nav-bottom">
            <div className="container-card">
              <Card className="card-user">
                <div
                  className="tooltip subcontainer-card-user"
                  data-pr-tooltip={clientName}
                  data-pr-position="right"
                >
                  <div className="card-user-info">
                    <img alt="Icon user verde" src={Avatar} />
                    <div className="container-client-info">
                      <div>
                        <span>{customClientName(clientName)}</span>
                      </div>
                      <div>
                        <span>ID da conta: {clientID}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            <div className="links-bottom">
              <NavLink to="/configuracoes" className="nav-link">
                <i className="pi pi-cog" />
                <span>Configurações</span>
              </NavLink>
              <a
                onClick={() => setVisibleDialog(!visibleDialog)}
                href="#"
                className="nav-link"
              >
                <i className="pi pi-sign-out" />
                <span>Logout</span>
                <DialogLogout visibleDialog={visibleDialog} />
              </a>
            </div>
          </div>
        </nav>
      )}
    </>
  );
}

export default Navbar;
