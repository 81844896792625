
import './styles.css'



const SkeletonCard = (props) => {
    return (
      <>
        <span className="label"></span>
        <div className="circle">
          <div className="content">
            <span></span>
          </div>
        </div>
      </>
    );
  };
  export default SkeletonCard;