class Util {
  getDatasIntervalo(startDate, endDate) {
    let datas = [];
    while (
      this.formatarDataSemHora(startDate) !== this.formatarDataSemHora(endDate)
    ) {
      datas.push(this.formatarDataSemHora(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }
    return "datas";
  }

  getDiffDays(startDate, endDate) {
    const diffTime = Math.abs(endDate - startDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
  }

  formatarDataSemHora(data) {
    try {
      return new Date(data).toISOString().split("T")[0];
    } catch (error) {
      return "";
    }
  }

  formartarDataPadrao(data) {
    let dataTransformada = data.split("-").reverse().join("/");
    return dataTransformada;
  }

  getStartDate(day_start_current_billing_month, dataFinal) {
    if (dataFinal === null) return "";

    if (dataFinal.getUTCDate() - 1 > day_start_current_billing_month)
      return new Date(
        dataFinal.getUTCFullYear(),
        dataFinal.getUTCMonth(),
        day_start_current_billing_month,
      );

    return new Date(
      dataFinal.getUTCFullYear(),
      dataFinal.getUTCMonth() - 1,
      day_start_current_billing_month,
    );
  }

  getDiffMonths(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    return Math.max(
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        endDate.getMonth() -
        startDate.getMonth(),
      0,
    );
  }

  getInitialDateConsumptionTrend(
    dataInicio,
    dataFim,
    lastMonths = 3,
    billinDay = 15,
  ) {
    const startDate = new Date(dataInicio);
    const endDate = new Date(dataFim);

    if (
      this.getDiffMonths(startDate, endDate) <= 1 &&
      startDate.getUTCDate() === billinDay &&
      endDate.getUTCMonth() === new Date().getUTCMonth()
    ) {
      if (endDate.getUTCDate() < billinDay) {
        endDate.setUTCMonth(endDate.getUTCMonth() - 1);
        endDate.setDate(billinDay);
      }

      startDate.setUTCMonth(startDate.getUTCMonth() - lastMonths);
      startDate.setDate(15);
    }

    return {
      endDate: endDate,
      startDate: startDate,
    };
  }
  isEmpty(texto) {
    return texto === "" || texto === undefined || texto === null;
  }

  isEmptyNumber(numero) {
    return numero === undefined || numero === null;
  }

  gerarDataExpiracaoSessao() {
    var dataAtual = new Date();
    dataAtual.setUTCHours(dataAtual.getUTCHours() + 1);

    return dataAtual;
  }

  formatNumber(valor, casasDecimais) {
    try {
      return valor.toLocaleString("pt-BR", {
        minimumFractionDigits: casasDecimais !== undefined ? casasDecimais : 2,
        maximumFractionDigits: casasDecimais !== undefined ? casasDecimais : 2,
      });
    } catch (error) {
      return 0;
    }
  }

  formatReal(value) {
    return new Intl.NumberFormat("pt-br", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  }

  getAcessToken() {
    try {
      return {
        access_token: localStorage.getItem("access_token"),
        Content: "Application/json",
      };
    } catch (error) {
      console.log(error);
    }
  }

  getTokenAPIBackEnd() {
    try {
      return {
        headers: {
          Authorization: localStorage.getItem("Authorization-Token"),
        },
      };
    } catch (error) {
      console.log(error);
    }
  }

  formatarData(data) {
    const dataAuxiliar = new Date(data);
    return (
      adicionarZero(dataAuxiliar.getDate()) +
      "/" +
      adicionarZero(dataAuxiliar.getMonth() + 1) +
      "/" +
      dataAuxiliar.getFullYear() +
      " " +
      adicionarZero(dataAuxiliar.getHours()) +
      ":" +
      adicionarZero(dataAuxiliar.getMinutes()) +
      ":" +
      adicionarZero(dataAuxiliar.getSeconds())
    );
  }

  getRegioes() {
    return [
      { nome: "Uberlândia", uf: "MG", id: 3 },
      { nome: "São Paulo", uf: "SP", id: 4 },
      { nome: "Todos", uf: "", id: 0 },
    ];
  }

  getInstances() {
    return [
      { nome: "Instâncias", nome_origem: "OPENSTACK_VM" },
      { nome: "Volumes", nome_origem: "OPENSTACK_VOLUME" },
      { nome: "IPs Persistentes", nome_origem: "OPENSTACK_FLOATIP" },
      { nome: "Balanceadores de Carga", nome_origem: "OPENSTACK_LOADBALANCER" },
      { nome: "Snapshots", nome_origem: "OPENSTACK_VOLUME" },
      { nome: "Todos", nome_origem: "" },
    ];
  }

  getToastMessages(severity, summary, detail) {
    const params = (severity, summary, detail) => {
      return {
        severity: severity,
        summary: summary,
        detail: detail,
      };
    };

    return {
      configurations: {
        error: {
          ...params(
            "error",
            "Erro",
            "Ops! Não conseguimos alterar a notificação",
          ),
        },
        success: {
          ...params("success", "Sucesso", "Notificação alterada com sucesso!"),
        },
      },
    };
  }

  groupBy(key) {
    return function group(array) {
      return array.reduce((acc, obj) => {
        const property = obj[key];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    };
  }

  typeMessage(type) {
    const types = {
      "Não lidas": "NAO_LIDA",
      Lidas: "LIDA",
      Cancelado: "CANCELADO",
    };

    return types[type];
  }
}

function adicionarZero(numero) {
  if (numero <= 9) return "0" + numero;
  else return numero;
}

export default new Util();
