import React from "react";
import './style.css'
import { Tooltip } from "primereact/tooltip";
import Util from "../../utils/Util";
import { Skeleton } from 'primereact/skeleton';

export default function ConsumptionTrend(props) {

  const formatDate = (date) => Util.formartarDataPadrao(Util.formatarDataSemHora(date))
  const initialDates = Util.getInitialDateConsumptionTrend(props.startDate, props.endDate)

  return (
    <div className={props.datainfo !== null ? "container-chart-after" : "container-chart"}>
      <Tooltip target=".container-chart-below" />
      <div
        className="container-chart-below"
        data-pr-tooltip={
          `Taxa de crescimento referentes ao intervalo ${formatDate(initialDates.startDate)} a ${formatDate(initialDates.endDate)}`
        }
        data-pr-position="top">
        {props.datainfo === null
          ? <div><Skeleton height="100%" shape="p-skeleton-none" /></div>
          : <div></div>
        }
        <span>
          {(() => {
            if (props.datainfo !== null)
              if (props.datainfo.averageLastMonth > props.datainfo.averageFirstMonth)
                return String(Number(props.datainfo.rate).toFixed(0)) + '%'

            return String(0) + '%'

          })()}
        </span>

      </div>
      <Tooltip target=".container-chart-above" />
      <div
        className="container-chart-above"
        data-pr-tooltip={
          `Taxa de decrescimento referentes ao intervalo ${formatDate(initialDates.startDate)} a ${formatDate(initialDates.endDate)}`
        }
        data-pr-position="top">
        {props.datainfo == null
          ? <div><Skeleton height="100%" shape="p-skeleton-none" /></div>
          : <div></div>
        }
        <span>
          {(() => {
            if (props.datainfo !== null)
              if (props.datainfo.averageLastMonth < props.datainfo.averageFirstMonth)
                return String(Number(props.datainfo.rate).toFixed(2)) + '%'

            return String(0) + '%'
          })()}
        </span>
      </div>
    </div >
  )
}
