import React from "react";
import { ConfirmDialog } from 'primereact/confirmdialog';
import './styles.css'

export default function Dialog({...props}){
  return(
      <ConfirmDialog 
        visible={props.visible}
        onHide={false}
        acceptLabel="Sim"
        rejectLabel="Não"
        accept={props.accept}
        message={props.message}
        header={props.header}
        icon={props.icon}
      />
  );
}
