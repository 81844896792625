class GraficoUtil {
  toDatasGrafico(values) {
    let dadas = []
    values.map((item) => {
      return dadas.push(item.date)
    })
    return dadas;
  }

  toDadosGrafico(values) {

    return values.map((item) => {
      return item.used
    })

  }


  toDadosTypeGrafico(values) {
    let types = []

    values.map((item) => {
      return types.push(item.type)
    })
    return types;
  }

  toDadosFlavorGrafico(values) {
    let flavors = []
    values.map((item) => {
      return flavors.push(item.flavor)
    })
    return flavors;
  }

  getListColors() {

    //Paleta 2
    return [
      "#fd7f6f",
      "#7eb0d5",
      "#b2e061",
      "#bd7ebe",
      "#ffb55a",
      "#ffee65",
      "#beb9db",
      "#fdcce5",
      "#8bd3c7"
    ]

  }

  toContratadoDiaGrafico(totalContradado, period) {
    const totaldaysbilling = 31
    return Array(
      period ? period : totaldaysbilling
    ).fill(totalContradado / totaldaysbilling);
  }

  getLightOptions() {
    return {
      type: 'outlabeledPie',
      plugins: {

        datalabels: {
          formatter: (value, context) => {
            const sum = context.dataset.data.reduce((acc, val) => acc + val)

            return sum === 0 ? 0 : ((value / sum) * 100).toFixed(0) + '%'
          },
          color: '#FFFFFF',
          font: {
            weight: 'bold',
            size: 14,
          },

        },
        legend: {
          labels: {
            color: '#495057'
          }
        },
      },

    };
  }

  toForecastConsumption(resources) {

    const used = resources.reduce((acc, el) => {
      const qtd = acc.qtd + 1
      const total = acc.total + el.used
      return {
        qtd: qtd,
        total: total,
        average: total / qtd
      }
    }, { qtd: 0, total: 0, average: 0 })

    return ((31 - used.qtd) * used.average) + used.total

  }

  toGrowthRate(creditosConsumido, forecastConsumption) {
    const valuessort = [creditosConsumido, forecastConsumption].sort((a, b) => a - b)
    return valuessort[0] === 0 ? 0 : (((valuessort[1] - valuessort[0]) / valuessort[0]) * 100).toFixed(2)
  }

}

export default new GraficoUtil()