import "./style.css";
import { Link } from 'react-router-dom';

function NotFound(props) {
  
  return (
    <div className='card flex flex-wrap align-items-center justify-content-center container-erro'>
      <div id="div-not-found" className="field">
        <img alt="Mensagem de erro" width={ props.size ? props.size : 400} src={props.errorIcon}></img>
        <div className="field align-items-center justify-content-center">
          <h2>{props.msgPrimary}</h2>
          {props.msgSecundary ?
            <>
              <h1><Link className='p-button p-component' to="/dashboard">{props.msgSecundary}</Link></h1>
            </>
            : ''
          }
        </div>     
      </div>  
    </div>
  );
}

export default NotFound;
