import { Dropdown } from "primereact/dropdown";


function selectedTemplate(option, props) {
    if (option) {
        return (
            <div className="country-item country-item-value">
                {option.uf ? option.name + ' - ' + option.uf : option.nome}
            </div>
        );
    }

    return (
        <span>
            {props.placeholder}
        </span>
    );
  }

  function optionTemplate(option) {
    return (
        <div className="country-item">
            {option.uf  ? option.name + ' - ' + option.uf : option.nome}
        </div>
    );
}

export default function DropdownOption(props){
    return (
        <Dropdown {...props} valueTemplate={selectedTemplate} itemTemplate={optionTemplate} />
    );
}