import React, { useState, useRef, useEffect } from 'react'
import { Divider } from 'primereact/divider';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import configurationService from '../../services/ConfigurationService'
import Util from '../../utils/Util'
import './styles.css'

function Configuracoes() {

  const [configs, setConfigs] = useState({})
  const [loading, setLoading] = useState()
  const toast = useRef(null)

  var toastMessages = Util.getToastMessages().configurations

  useEffect(() => {
    async function fetchInstances() {
      setLoading(true);
      let response = await requestFetchInstances()

      Object.keys(response).forEach((key) => {
        response[key] = !!response[key]
      })

      setConfigs(response)
      setLoading(false);
    }

    fetchInstances()

  }, [])

  const show = (status) => {
    toast.current.show({
      severity: status.severity,
      summary: status.summary,
      detail: status.detail
    });
  };


  async function requestFetchInstances() {

    return await configurationService.getConfigs(
      window.localStorage.getItem("client_id"),
    );

  }

  async function onChangeNotification(value) {
    setLoading(true)

    let configsChange = ({
      ...configs,
      notificarSobreuso: +value
    })

    let response = await requestChangeConfig(configsChange)

    if (response.length === 0) {

      show(toastMessages.error)

    } else {

      setConfigs((e) => {
        return ({
          ...e,
          notificarSobreuso: value
        })
      })

      show(toastMessages.success)
    }

    setLoading(false)
  }

  async function requestChangeConfig(configsChange) {
    return await configurationService.changeConfig(
      window.localStorage.getItem("client_id"),
      configsChange
    )
  }


  return (
    <div className='margin-itens'>
      <h1>Configurações</h1>
      <h4>Selecione os tipos de notificações que deseja receber</h4>
      <Divider />
      <div className='container-notifications'>
        <div className='descriptions'>
          <span>Notificações dashboard e email</span>
          <h5>Obtenha notificações no dashboard e email informando detalhes do seu uso mensal</h5>
        </div>
        <div className='btn-notifications'>
          <InputSwitch
            checked={configs.notificarSobreuso}
            onChange={(e) => onChangeNotification(e.value)}
          />
          <div>
            <span>Notificar Sobreuso</span>
            <h5>Notificação de sobreuso dos recursos</h5>
          </div>
        </div>
      </div>
      <Toast ref={toast} />

    </div>
  );
}

export default Configuracoes
