import { api } from './api'
import Util from '../utils/Util'

class ConfigurationService {

  async getConfigs(customerId) {


    try {

      const uri = "/cloudplus/subscriptions/"
        + customerId + "/configs"


      let response = await api.get(uri, Util.getTokenAPIBackEnd())


      return response.data

    } catch (e) {
      return []
    }

  }

  async changeConfig(customerId, configs) {

    try {

      const uri = "/cloudplus/subscriptions/"
        + customerId + "/configs"

      let response = await api.put(uri, configs, Util.getTokenAPIBackEnd())

      return response

    } catch (e) {
      return []
    }

  }

}

export default new ConfigurationService()
