class Export {
  download(file, fileName) {
    console.log("download - ", file);
    var arraybuffer = new Blob([file], { type: "application/octet-stream" });

    var reader = new FileReader();
    reader.readAsDataURL(arraybuffer);
    reader.onloadend = function () {
      var base64data = reader.result;
      console.log("download2 - ", base64data);
      var downloadLink = document.createElement("a");
      downloadLink.href = base64data;
      downloadLink.download = fileName;
      downloadLink.target = "_blank";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
  }
  openNewTab(file) {
    let newWindow = window.open("");
    newWindow.document.write(
      "<iframe width='100%' height='100%' src='" + file + "'></iframe>"
    );
  }

  downloadBlobFromUrl(blobUrl, filename) {
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename || "downloaded-file"; // Nome sugerido para o arquivo ao ser baixado

    // Verifica se o navegador suporta o atributo 'download'
    if ("download" in link) {
      // Simula um clique no link para iniciar o download
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // Se o atributo 'download' não for suportado, redireciona para a URL
      window.location.href = blobUrl;
    }
  }
}

export default new Export();
