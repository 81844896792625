import React from "react";
import './styles.css'


export default function Footer() {

    const urlForm = "https://docs.google.com/forms/d/1oz3WveC_UULsz4D5SQ0Fsc3nS_1JtobdtVIpisWNSi0/edit"

    return (
        <footer>
            <ul>
                <li>
                    <a
                        href={urlForm}
                        className="info-icons"
                        target="_blank"
                        rel="dns-prefetch noreferrer">
                        <i className="pi pi-code"></i>
                        <div className="vertical"/>
                        <span>Dashboard Cloudplus (Versão Beta)</span>
                    </a>
                </li>
                <li>
                    <a
                        href={urlForm}
                        className="info-icons"
                        target="_blank" 
                        rel="dns-prefetch noreferrer">
                        <i className="pi pi-comments"></i>
                        <div className="vertical" />
                        <span>Nos dê um feedback</span>
                    </a>
                </li>
            </ul>
        </footer>
    )
}