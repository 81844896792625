import { api } from "./api"


export async function getTokenJWT(){
    try{
        const uri = '/login'
        
        const data = {
            login: process.env.REACT_APP_LOGIN,
            senha: process.env.REACT_APP_SENHA
        }

        let response = await api.post(uri, JSON.stringify(data))

        return response
    }catch(error){
        console.log(error)
        return []
    }
}

