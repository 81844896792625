import axios from "axios";

export const sensedia = axios.create({
  baseURL: process.env.REACT_APP_SENSEDIA_APP_BASE_URL
});


export const api = axios.create({
  baseURL: process.env.REACT_APP_DASHBOARD_API
});

///export default [sensedia, api];
