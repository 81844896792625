import { api } from "./api";
import Util from "../utils/Util";

class SnapshotService {
  async fetchSnapshots(customerId, startDate, endDate, regionId) {
    try {
      var uri =
        "/cloudplus/snapshots" +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&customerId=" +
        customerId +
        "&regionId=" +
        regionId;

      let resp = await api.get(uri, Util.getTokenAPIBackEnd());

      return { resources: resp.data };
    } catch (error) {
      return [];
    }
  }
}

export default new SnapshotService();
