import React from "react";
import { Dialog } from "primereact/dialog";
import "./styles.css";

export default function DialogInformation(props) {
  return (
    <Dialog
      visible={props.visible}
      onHide={props.onHide}
      header={props.header}
      breakpoints={{ "960px": "75vw" }}
      className="dialog-information"
    >
      {props.children}
    </Dialog>
  );
}
