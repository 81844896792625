import Util from "../utils/Util";
import {api} from "./api";

class RegionService {
  async findAllCloudOpenStack() {
    try {
      var uri = "/cloudplus/regions";
      let response = await api.get(uri, Util.getTokenAPIBackEnd())

      return response.data
    } catch (error) {
      return []
    }
  }
}

const regionService = new RegionService();
export default regionService;