import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import volumeIcon from "../../assets/volume.png";
import snapshotIcon from "../../assets/snapshot.png";
import "./styles.css";
import {
  TraductionSnapshotTitles,
  TraductionVolumeTitles,
} from "./traductions";
import { Tooltip } from "primereact/tooltip";
import Util from "../../utils/Util";

const TemplateInformationSnapshot = (props) => {
  return (
    <div className="information-snapshot">
      {["assetId", "assetName", "status", "size", "createdAt", "updatedAt"].map(
        (item) => {
          return (
            <span className="my-1">
              <b className="text-sm">{TraductionSnapshotTitles(item)}: </b>
              {["createdAt", "updatedAt"].includes(item)
                ? Util.formatarData(Util.formatarDataSemHora(props.info[item]))
                : props.info[item]}
            </span>
          );
        },
      )}
    </div>
  );
};

const TemplateInformationVolume = (props) => {
  return (
    <div className="information-snapshot">
      {["assetId", "assetName", "disk_type", "disk_size", "imagem"].map(
        (item) => {
          return (
            <span className="text-sm my-1">
              <b className="text-sm">{TraductionVolumeTitles(item)}: </b>
              {props.info[item]}
            </span>
          );
        },
      )}
    </div>
  );
};

function DialogSnapshot(props) {
  return (
    <Dialog
      header={`Snapshot: ${props.data.assetName}`}
      visible={props.visible}
      onHide={props.onHide}
      className="container-dialog-snap"
    >
      <div className="container-card-snap">
        <Card
          title={
            <div className="flex">
              <img
                src={snapshotIcon}
                alt="Ícone de volume"
                style={{
                  height: "23px",
                  marginRight: "10px",
                }}
              />
              <span className="text-base">Snapshot</span>
            </div>
          }
          className="mr-2 mb-2 "
        >
          <TemplateInformationSnapshot info={props.data} />
        </Card>
        <Tooltip target=".knob" content="Originado do volume " />
        <i
          className="knob pi pi-arrow-right"
          style={{ cursor: "pointer" }}
          tooltipOptions={{ position: "top" }}
        />
        <Card
          title={
            <div>
              <img
                src={volumeIcon}
                alt="Ícone de volume"
                style={{ height: "23px", marginRight: "10px" }}
              />
              <span className="text-base">Volume</span>
            </div>
          }
          className="ml-2 mb-2 "
        >
          <TemplateInformationVolume info={props.data.sourceVolume} />
        </Card>
      </div>
    </Dialog>
  );
}

export default DialogSnapshot;
