import React, { useEffect, useState, lazy, Suspense } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Chart } from "primereact/chart";
import { Panel } from "primereact/panel";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import "./style.css";
import CardInformation from "../../Components/CardInformation";
import ViewStateResources from "../../Components/ViewStateResources";
import TagCard from "../../Components/TagCard";
import util from "../../utils/Util";
import { Regions } from "../../utils/Regions";
import billingSummaryService from "../../services/BillingSummaryService";
import Util from "../../utils/Util";
import GraficoUtil from "../../utils/GraficoUtil";
import billingDetailService from "../../services/BillingDetailService";
import { Skeleton } from "primereact/skeleton";
import { SplitButton } from "primereact/splitbutton";
import SkeletonCard from "../../Components/chart";
import NotFound from "../../Components/NotFound";
import NotFoundIcon from "../../assets/notfoundsearch.png";
import { ProgressSpinner } from "primereact/progressspinner";
import { Tooltip } from "primereact/tooltip";
import ConsumptionTrend from "../../Components/ConsumptionTrend";
import { Chart as ChartJsPie } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DialogInformation from "../../Components/DialogInformation";
import icon from "../../assets/banner.jpeg";
import Export from "../../utils/Export";
import regionService from "../../services/RegionService";

function Dashboard(props) {
  ChartJsPie.register(ChartDataLabels);

  const dayStartCurrentBillingMonth = 15;

  const [activeIndex, setActiveIndex] = useState(0);
  const [dataFim, setDataFim] = useState(new Date());
  const [dataInicio, setDataInicio] = useState(
    util.getStartDate(dayStartCurrentBillingMonth, dataFim),
  );
  const [regiao, setRegiao] = useState({ id: 0 });
  const [visibilidadeDialogDia, setVisibilidadeDialogDia] = useState(false);
  const [openDialogInformation, setOpenDialogInformation] = useState(true);
  const [headerDialogDia, setHeaderDialogDia] = useState("");
  const [headerConsumoTotal, setHeaderConsumoTotal] = useState(0);
  const [creditosConsumido, setCreditosConsumido] = useState(0);
  const [creditosContratado, setCreditosContratado] = useState(0);
  const [forecastConsumption, setForecastConsumption] = useState(0);
  const [comsumptionTrend, setConsumptionTrend] = useState(null);
  const [dataGraficoPrincipal, setDataGraficoPrincipal] = useState(null);
  const [regions, setRegions] = useState([]); //[{ nome: "Uberlândia", uf: "MG", id: 3 }, { nome: "São Paulo", uf: "SP", id: 4 }, { nome: "Todos", uf: "", id: 0 }]
  const [basicData, setBasicData] = useState({ labels: 0 });
  const [resourcesDeleted, setResourcesDeleted] = useState([]);
  const [resourcesOveruse, setResourcesOveruse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingResourcesOveruse, setLoadingResourcesOveruse] = useState(false);
  const [loadingResourcesDeleted, setLoadingResourcesDeleted] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingConsumoTipoDisco, setLoadingConsumoTipoDisco] = useState(false);
  const [loadingConsumoTipo, setLoadingConsumoPorTipo] = useState(false);
  const [loadingConsumoFlavor, setLoadingConsumoFlavor] = useState(false);

  const minDate = new Date("2022-06-16");

  const itemsExports = [
    {
      label: "Excel",
      icon: "pi pi-file-excel",
      command: (e) => {
        exportConsumo();
      },
    },
  ];

  const getLightTheme = () => {
    let basicOptions = {
      id: "basicOptions",
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
            font: {
              weight: "bold",
              size: 15,
            },
          },
        },

        datalabels: {
          formatter: (value, context) => "",
        },

        tooltip: {
          callbacks: {
            label: function (context) {
              return Util.formatNumber(context.raw);
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
          min: 0,
        },
      },
      onClick: function (e) {
        // Quando o click estiver em cima da linha de creditos consumido
        if (
          e.chart.tooltip.dataPoints !== undefined &&
          e.chart.tooltip.labelColors !== undefined &&
          e.chart.tooltip.labelColors[0].borderColor === "#f21b1b"
        )
          preencheDialogDiaGrafico(e.chart.tooltip.dataPoints[0]);
      },
    };

    let multiAxisOptions = {
      stacked: false,
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          type: "linear",
          display: true,
          position: "left",
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",
          ticks: {
            color: "#495057",
          },
          grid: {
            drawOnChartArea: false,
            color: "#ebedef",
          },
        },
      },
    };

    return {
      basicOptions,
      multiAxisOptions,
    };
  };

  const [chartDataTipo, setChartDataTipo] = useState(null);
  const [chartDataRegiao, setChartDataRegiao] = useState(null);
  const [chartDataFlavor, setChartDataFlavor] = useState(null);
  const [chartDataTipoVolume, setChartDataTipoVolume] = useState(null);

  const { basicOptions } = getLightTheme();

  function regiaoOptionTemplate(option) {
    return (
      <div className="country-item">{option.name + " - " + option.uf}</div>
    );
  }

  function selectedRegiaoTemplate(option, props) {
    if (option) {
      return (
        <div className="country-item country-item-value">
          {option.name + " - " + option.uf}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  }

  function preencheDialogDiaGrafico(data) {
    setHeaderDialogDia(data.label);
    setHeaderConsumoTotal(data.raw);
    setVisibilidadeDialogDia(true);
  }

  async function fetchRegions() {
    let regions = await regionService.findAllCloudOpenStack();
    regions.push({ id: 0, name: "Todos", uf: "" });
    setRegions(regions);
  }

  useEffect(() => {
    async function carregaValores() {
      fetchRegions();
      if (dataGraficoPrincipal === null) pesquisar();
    }
    carregaValores();
  }, []);

  useEffect(async () => {
    if (
      dataGraficoPrincipal != null &&
      (regiao.id === Regions.SAO_PAULO || regiao.id === Regions.TODAS_REGIOES)
    ) {
      setLoadingResourcesDeleted(true);
      const subscriptionId = dataGraficoPrincipal.resources[0].subscriptionId;
      const resourcesDeleted = await fetchResourcesDeleted(subscriptionId);

      setResourcesDeleted(resourcesDeleted);
      setLoadingResourcesDeleted(false);
    }
  }, [dataGraficoPrincipal]);

  function atualizarGraficoPorTipo(dadosPorType) {
    setChartDataTipo({
      id: "dataType",
      labels: GraficoUtil.toDadosTypeGrafico(dadosPorType.resources),
      datasets: [
        {
          data: GraficoUtil.toDadosGrafico(dadosPorType.resources),
          backgroundColor: GraficoUtil.getListColors(),
          hoverBackgroundColor: GraficoUtil.getListColors(),
        },
      ],
      options: {
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            color: "#36A2EB",
          },
        },
      },
    });
  }

  function atualizarGraficoPorDiskType(dadosPorDiskType) {
    setChartDataTipoVolume({
      id: "dataTypeVolume",
      labels: GraficoUtil.toDadosTypeGrafico(dadosPorDiskType.resources),
      datasets: [
        {
          data: GraficoUtil.toDadosGrafico(dadosPorDiskType.resources),
          backgroundColor: GraficoUtil.getListColors(),
          hoverBackgroundColor: GraficoUtil.getListColors(),
        },
      ],
    });
  }

  function atualizarGraficoConsumoPorFlavor(data) {
    setChartDataFlavor({
      id: "dataFlavor",
      labels: GraficoUtil.toDadosFlavorGrafico(data.resources),
      datasets: [
        {
          data: GraficoUtil.toDadosGrafico(data.resources),
          backgroundColor: GraficoUtil.getListColors(),
          hoverBackgroundColor: GraficoUtil.getListColors(),
        },
      ],
    });
  }

  function atualizarDadosGraficos(dados) {
    setCreditosConsumido(dados.totalUsed);
    setForecastConsumption(GraficoUtil.toForecastConsumption(dados.resources));
    setCreditosContratado(dados.recursosContratado);
    setBasicData({
      labels: GraficoUtil.toDatasGrafico(dados.resources),
      datasets: [
        {
          label: "Contratado",
          data: GraficoUtil.toContratadoDiaGrafico(
            dados.recursosContratado,
            Util.getDiffDays(dataFim, dataInicio),
          ),
          fill: false,
          borderColor: "#67e838",
          tension: 0.4,
        },
        {
          label: "Consumido",
          data: dados.resources.map((data) => data.used),
          fill: true,
          borderColor: "#f21b1b",
          tension: 0.4,
        },
      ],
    });

    setChartDataRegiao({
      id: "dataRegion",
      labels: ["Uberlândia", "São Paulo", "Nova Uberlândia"],
      datasets: [
        {
          data: GraficoUtil.toDadosGrafico([
            { used: dados.totalUseUdi },
            { used: dados.totalUsedSp },
            { used: dados.totalUseNewUdi },
          ]),
          backgroundColor: GraficoUtil.getListColors(),
          hoverBackgroundColor: GraficoUtil.getListColors(),
        },
      ],
    });
  }

  async function fetchConsumoGraficoPrincipal() {
    setLoading(true);
    const response = await billingSummaryService.findConsumoGraficoPrincipal(
      window.localStorage.getItem("client_id"),
      Util.formatarDataSemHora(dataInicio),
      Util.formatarDataSemHora(dataFim),
      regiao !== undefined ? regiao.id : 0,
    );
    setLoading(false);
    atualizarDadosGraficos(response);
    setDataGraficoPrincipal(response);
  }

  async function fetchUsoPorType() {
    setLoadingConsumoPorTipo(true);
    const responseConsumoPorType = await billingDetailService.findUsoPorType(
      Util.formatarDataSemHora(dataInicio),
      Util.formatarDataSemHora(dataFim),
      window.localStorage.getItem("client_id"),
      regiao !== undefined ? regiao.id : 0,
    );
    responseConsumoPorType.resources = responseConsumoPorType.resources.filter(
      (item) => item.type !== "Redes",
    );

    atualizarGraficoPorTipo(responseConsumoPorType);
    setLoadingConsumoPorTipo(false);
  }

  async function fetchUsoPorFlavor() {
    setLoadingConsumoFlavor(true);
    const responseConsumoPorFlavor =
      await billingDetailService.findConsumoPorFlavor(
        Util.formatarDataSemHora(dataInicio),
        Util.formatarDataSemHora(dataFim),
        window.localStorage.getItem("client_id"),
        regiao !== undefined ? regiao.id : 0,
      );
    atualizarGraficoConsumoPorFlavor(responseConsumoPorFlavor);
    setLoadingConsumoFlavor(false);
  }

  async function fetchConsumoTipoDisco() {
    setLoadingConsumoTipoDisco(true);
    const responseConsumoPorDiskType =
      await billingDetailService.findUsoPorDiskType(
        Util.formatarDataSemHora(dataInicio),
        Util.formatarDataSemHora(dataFim),
        window.localStorage.getItem("client_id"),
        regiao !== undefined ? regiao.id : 0,
      );
    responseConsumoPorDiskType.resources =
      responseConsumoPorDiskType.resources.filter((item) => item.used > 0);
    atualizarGraficoPorDiskType(responseConsumoPorDiskType);
    setLoadingConsumoTipoDisco(false);
  }

  async function fetchConsumptionTrend() {
    const Date = Util.getInitialDateConsumptionTrend(dataInicio, dataFim);

    const responseConsumptionTrend =
      await billingDetailService.findConsumptionTrend(
        Util.formatarDataSemHora(Date.startDate),
        Util.formatarDataSemHora(Date.endDate),
        window.localStorage.getItem("client_id"),
        regiao !== undefined ? regiao.id : 0,
      );
    setConsumptionTrend(responseConsumptionTrend);
  }

  async function fetchResourcesDeleted(subscriptionId) {
    const responseResourcesDeleted =
      await billingDetailService.getResourcesDeleted(subscriptionId);

    return responseResourcesDeleted;
  }

  async function fetchResourcesOveruse() {
    setLoadingResourcesOveruse(true);
    if (
      regiao.id === Regions.SAO_PAULO ||
      regiao.id === Regions.TODAS_REGIOES
    ) {
      const dateCurrent = new Date();

      dateCurrent.setDate(dateCurrent.getDate() - 2);

      const responseResourcesOveruse =
        await billingDetailService.getResourcesOveruse(
          Util.formatarDataSemHora(dateCurrent),
          Util.formatarDataSemHora(dateCurrent),
          window.localStorage.getItem("client_id"),
          Regions.TODAS_REGIOES,
        );

      setResourcesOveruse(responseResourcesOveruse);
      setLoadingResourcesOveruse(false);

      return responseResourcesOveruse;
    }
  }

  async function pesquisar() {
    await Promise.all([
      fetchConsumoTipoDisco(),
      fetchConsumoGraficoPrincipal(),
      fetchUsoPorType(),
      fetchUsoPorFlavor(),
      fetchConsumptionTrend(),
      fetchResourcesOveruse(),
    ]);
  }
  async function exportConsumo() {
    setLoadingExport(true);
    const response = await billingDetailService.findExportConsumo(
      Util.formatarDataSemHora(dataInicio),
      Util.formatarDataSemHora(dataFim),
      window.localStorage.getItem("client_id"),
      regiao !== undefined ? regiao.id : 0,
    );

    Export.download(response, "ConsumoDetalhado.xlsx");
    setLoadingExport(false);
  }

  const openForm = () => {
    window.open(
      "https://docs.google.com/forms/d/1oz3WveC_UULsz4D5SQ0Fsc3nS_1JtobdtVIpisWNSi0/edit",
      "_blank",
    );
    setOpenDialogInformation(false);
  };

  const DialogDiaConsumo = lazy(
    () => import("../../Components/DialogDiaConsumo"),
  );
  const renderLoader = () => <p>Loading</p>;

  const DetailsComponent = () => (
    <Suspense fallback={renderLoader()}>
      <DialogDiaConsumo
        regiao={regiao}
        data={headerDialogDia}
        consumoTotal={headerConsumoTotal}
        visibilidade={visibilidadeDialogDia}
        regions={regions}
        onHide={setVisibilidadeDialogDia}
      />{" "}
      : ''
    </Suspense>
  );

  return (
    <>
      <div className="margin-itens">
        <h1 className="mb-5">Dashboard</h1>
        <Panel header="Filtros" toggleable>
          <div className="formgrid grid">
            <div className="field col-12 mb-2 md:col-2">
              <label htmlFor="firstname2">Início</label>
              <Calendar
                id="icon"
                value={dataInicio}
                onChange={(e) => setDataInicio(e.value)}
                showIcon
                dateFormat="dd/mm/yy"
                mask="99/99/9999"
                minDate={minDate}
                aria-label="data inicial"
              />
              <label
                htmlFor="date"
                className={
                  dataInicio === null
                    ? "p-error"
                    : dataInicio > dataFim
                      ? "p-error-date"
                      : "p-message"
                }
              ></label>
            </div>
            <div className="field col-12 mb-2 md:col-2">
              <label htmlFor="lastname2">Fim</label>
              <Calendar
                id="icon"
                value={dataFim}
                onChange={(e) => setDataFim(e.value)}
                showIcon
                dateFormat="dd/mm/yy"
                mask="99/99/9999"
                minDate={minDate}
                aria-label="data final"
              />
              <label
                htmlFor="date"
                className={dataFim === null ? "p-error" : "p-message"}
              ></label>
            </div>
            <div className="field col-12 mb-2 md:col-2">
              <label htmlFor="lastname2">Região</label>
              <Dropdown
                optionLabel="nome"
                value={regiao}
                options={regions}
                onChange={(e) => setRegiao(e.value)}
                placeholder="Selecione a Região"
                valueTemplate={selectedRegiaoTemplate}
                itemTemplate={regiaoOptionTemplate}
                aria-label="região"
              />
            </div>
            <div className="search-field">
              <Button
                icon="pi pi-search"
                onClick={pesquisar}
                disabled={
                  dataFim === null ||
                  dataInicio === null ||
                  dataInicio > dataFim
                }
              />
              <SplitButton
                label="Exportar"
                className="ml-2"
                model={itemsExports}
              />
            </div>
            <div className="field mt-4 md:col-1 ">
              {loadingExport ? (
                <ProgressSpinner
                  className="p-progress-spinner-color"
                  style={{ width: "22px", height: "22px" }}
                  strokeWidth="8"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Panel>

        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Dashboard">
            {!loading && basicData.labels.length <= 0 ? (
              <NotFound
                msgPrimary="Ops! Dados não encontrados"
                errorIcon={NotFoundIcon}
                size={300}
              />
            ) : (
              <>
                <div className="formgrid grid ">
                  <div className="grid grid-cols-3 gap-3 mb-5 ml-4">
                    <CardInformation
                      value={
                        loading ? (
                          <Skeleton shape="p-skeleton-none" />
                        ) : (
                          util.formatNumber(creditosContratado)
                        )
                      }
                      title="Recursos contratados"
                    />
                    <CardInformation
                      value={
                        loading ? (
                          <Skeleton shape="p-skeleton-none" />
                        ) : (
                          util.formatNumber(creditosConsumido)
                        )
                      }
                      title="Recursos consumidos"
                    />
                    {(() => {
                      if (
                        dataInicio !== null &&
                        dataFim != null &&
                        Util.getDiffMonths(dataInicio, dataFim) <= 1 &&
                        dataInicio.getUTCDate() ===
                          dayStartCurrentBillingMonth &&
                        dataFim.getUTCMonth() === new Date().getUTCMonth() &&
                        dataFim.getUTCFullYear() === new Date().getUTCFullYear()
                      ) {
                        return (
                          <>
                            <Tooltip target=".customClassName" />
                            <div
                              className="customClassName"
                              data-pr-tooltip="Previsão média de consumo"
                              data-pr-position="top"
                            >
                              <CardInformation
                                value={
                                  loading ? (
                                    <Skeleton shape="p-skeleton-none" />
                                  ) : (
                                    util.formatNumber(forecastConsumption)
                                  )
                                }
                                title="Previsão de consumo"
                                footer={
                                  loading ? (
                                    <Skeleton shape="p-skeleton-none" />
                                  ) : (
                                    <TagCard
                                      value={GraficoUtil.toGrowthRate(
                                        creditosContratado,
                                        forecastConsumption,
                                      )}
                                      primaryIcon="pi pi-percentage"
                                      secundaryIcon={
                                        forecastConsumption ===
                                        creditosContratado
                                          ? "pi pi-minus"
                                          : forecastConsumption <
                                              creditosContratado
                                            ? "pi pi-arrow-down-left"
                                            : "pi pi-arrow-up-right"
                                      }
                                      severity={
                                        forecastConsumption ===
                                        creditosContratado
                                          ? "info"
                                          : forecastConsumption <
                                              creditosContratado
                                            ? "success"
                                            : "danger"
                                      }
                                    />
                                  )
                                }
                              />
                            </div>
                          </>
                        );
                      }
                      return (
                        <>
                          <Tooltip target=".customClassName" />
                          <div
                            className="customClassName card-disabled"
                            data-pr-tooltip="Previsão média de consumo disponível somente para o início do faturamento corrente"
                            data-pr-position="top"
                          >
                            <CardInformation
                              value={
                                <Skeleton
                                  shape="p-skeleton-none"
                                  animation="none"
                                />
                              }
                              title="Previsão de consumo"
                              footer={
                                <Skeleton
                                  shape="p-skeleton-none"
                                  animation="none"
                                  width="35%"
                                />
                              }
                            />
                          </div>
                        </>
                      );
                    })()}
                  </div>
                  <div className="field col-12 mb-2">
                    <div className="field col-12 mb-2">
                      <div className="grid grid-cols-2 gap-2 mb-5">
                        <div className="field col-11 mb-2">
                          {loading ? (
                            <Skeleton height="100%" shape="p-skeleton-none" />
                          ) : (
                            <Chart
                              id="graficoPrincipal"
                              type="line"
                              data={basicData}
                              options={basicOptions}
                              className="cursor-pointer"
                            />
                          )}
                        </div>
                        <ConsumptionTrend
                          datainfo={comsumptionTrend}
                          startDate={dataInicio}
                          endDate={dataFim}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="field col-12 mb-2 md:col-12">
                    <h2 className="text-align-center">
                      Consumo vs Contratado por período
                    </h2>
                  </div>

                  <div className="field col-12 mb-2 md:col-3">
                    {loadingConsumoTipo ? (
                      <SkeletonCard />
                    ) : (
                      <Chart
                        id="chartType"
                        type="pie"
                        data={chartDataTipo}
                        options={GraficoUtil.getLightOptions()}
                      />
                    )}
                    <h2 className="text-align-center">
                      Consumo por tipo de Recurso
                    </h2>
                  </div>
                  <div className="field col-12 mb-2 md:col-3">
                    {loading ? (
                      <SkeletonCard />
                    ) : (
                      <Chart
                        id="chartRegion"
                        type="pie"
                        data={chartDataRegiao}
                        options={GraficoUtil.getLightOptions()}
                      />
                    )}
                    <h2 className="text-align-center">Consumo por Região</h2>
                  </div>
                  <div className="field col-12 mb-2 md:col-3">
                    {loadingConsumoFlavor ? (
                      <SkeletonCard />
                    ) : (
                      <Chart
                        id="chartFlavor"
                        type="pie"
                        data={chartDataFlavor}
                        options={GraficoUtil.getLightOptions()}
                      />
                    )}
                    <h2 className="text-align-center">Consumo por Flavor</h2>
                  </div>

                  <div className="field col-12 mb-2 md:col-3 teste">
                    {loadingConsumoTipoDisco ? (
                      <SkeletonCard />
                    ) : (
                      <Chart
                        id="chartTypeVolume"
                        className="zoom"
                        type="pie"
                        data={chartDataTipoVolume}
                        options={GraficoUtil.getLightOptions()}
                      />
                    )}
                    <h2 className="text-align-center">
                      Consumo por tipo de volumes
                    </h2>
                  </div>
                </div>

                {visibilidadeDialogDia ? DetailsComponent() : ""}
              </>
            )}
          </TabPanel>

          {(regiao.id === Regions.TODAS_REGIOES ||
            regiao.id === Regions.SAO_PAULO) &&
          dataGraficoPrincipal != null &&
          dataGraficoPrincipal.resources.length > 0 &&
          (resourcesDeleted.length > 0 || resourcesOveruse.length > 0) ? (
            <TabPanel header="Sobreuso e Exclusão">
              <ViewStateResources
                dataResources={dataGraficoPrincipal}
                loadingResourcesOveruse={loadingResourcesOveruse}
                loadingResourcesDeleted={loadingResourcesDeleted}
                resourcesDeleted={resourcesDeleted}
                resourcesOveruse={resourcesOveruse}
              />
            </TabPanel>
          ) : (
            <></>
          )}
        </TabView>
      </div>
    </>
  );
}

export default Dashboard;
