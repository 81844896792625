import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getGrantCode, isAuthenticated, setDataExpiracao } from "../../auth";
import { getTokenJWT } from "../../services/jwt";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCookies } from "react-cookie";

import "./style.css";
import { useQuery } from "../../hooks/useQuery";
import util from "../../utils/Util";

function Login() {
  const navigate = useNavigate();
  const query = useQuery();
  const userId = query.get("uid");
  const sessionId = query.get("sid");
  const [logout, setLogout] = useState(false);
  const timer = useRef(null);
  const minutesRefreshToken = 50;

  function setUserInStorage(userId, sessionId) {
    window.localStorage.setItem("uid", userId);
    window.localStorage.setItem("session_id", sessionId);
  }

  async function setItemInStorage(refreshToken, response) {
    if (refreshToken) {
      const responseGrantCode = await getGrantCode();

      if (responseGrantCode) {
        try {
          response = await isAuthenticated();
        } catch (error) {
          console.log(error);
          localStorage.clear();
        }
      }
    }

    if (response) {
      window.localStorage.setItem("client_id", response.data.ClienteID);
      window.localStorage.setItem("session_id", response.data.SessionID);
      window.localStorage.setItem("client_name", response.data.SessionUserName);

      setDataExpiracao(util.gerarDataExpiracaoSessao());
    }
  }

  useEffect(() => {
    async function verificarAutenticacao() {
      if (!util.isEmpty(sessionId) && !util.isEmpty(userId)) {
        setUserInStorage(userId, sessionId);
      }

      const responseGrantCode = await getGrantCode();
      const responseJwtToken = await getTokenJWT();

      if (responseGrantCode) {
        try {
          const response = await isAuthenticated();

          setItemInStorage(false, response);
          window.localStorage.setItem(
            "Authorization-Token",
            responseJwtToken.data.Authorization,
          );

          timer.current = setTimeout(() => {
            verificarAutenticacao();
            timer.current = null;
          }, minutesRefreshToken * 60000);

          setLogout(false);
          navigate("/dashboard");
        } catch (error) {
          console.log(error);
          localStorage.clear();
          navigate("/dashboard");
          return false;
        }
      }
    }
    verificarAutenticacao();
  }, [timer]);

  return (
    <div className="card  align-items-center justify-content-center card-login">
      <div className="field">
        <h3 style={{ textAlign: "center" }}>Carregando...</h3>
      </div>
      <ProgressSpinner
        className="flex"
        style={{ width: "50px", height: "50px" }}
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
    </div>
  );
}

export default Login;
