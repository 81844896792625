import { api } from "./api";
import Util from "../utils/Util";

class BillingDetailService {
  async findUsoDiaDetalhado(startDate, customerId, regionId) {
    var retorno = "";
    try {
      var uri =
        "/cloudplus/billing/detail/data-dashboard" +
        "?startDate=" +
        startDate +
        "&&customerId=" +
        customerId +
        "&&regionId=" +
        regionId;

      retorno = await api.get(uri, Util.getTokenAPIBackEnd());
      return retorno.data;
    } catch (error) {
      return [];
    }
  }

  async findUsoPorType(startDate, endDate, customerId, regionId) {
    var retorno = "";
    try {
      var uri =
        "/cloudplus/billing/detail/data-type" +
        "?startDate=" +
        startDate +
        "&&endDate=" +
        endDate +
        "&&customerId=" +
        customerId +
        "&&regionId=" +
        regionId;

      retorno = await api.get(uri, Util.getTokenAPIBackEnd());
      return retorno.data;
    } catch (error) {
      return [];
    }
  }

  async findUsoPorDiskType(startDate, endDate, customerId, regionId) {
    var retorno = "";
    try {
      var uri =
        "/cloudplus/billing/detail/data-disk-type" +
        "?startDate=" +
        startDate +
        "&&endDate=" +
        endDate +
        "&&customerId=" +
        customerId +
        "&&regionId=" +
        regionId;

      retorno = await api.get(uri, Util.getTokenAPIBackEnd());
      return retorno.data;
    } catch (error) {
      return [];
    }
  }

  async findExportConsumo(startDate, endDate, customerId, regionId) {
    var retorno = "";
    try {
      var uri =
        "/cloudplus/billing/detail/export" +
        "?startDate=" +
        startDate +
        "&&endDate=" +
        endDate +
        "&&customerId=" +
        customerId +
        "&&regionId=" +
        regionId;

      retorno = await api.get(uri, {
        ...Util.getTokenAPIBackEnd(),
        responseType: "blob",
      });
      return retorno.data;
    } catch (error) {
      return [];
    }
  }

  async findConsumoPorFlavor(startDate, endDate, customerId, regionId) {
    var retorno = "";
    try {
      var uri =
        "/cloudplus/billing/detail/data-flavor" +
        "?startDate=" +
        startDate +
        "&&endDate=" +
        endDate +
        "&&customerId=" +
        customerId +
        "&&regionId=" +
        regionId;

      retorno = await api.get(uri, Util.getTokenAPIBackEnd());
      return retorno.data;
    } catch (error) {
      return [];
    }
  }

  async fetchInstances(customerId, startDate, endDate, regionId, type) {
    try {
      var uri =
        "/cloudplus/billing/detail/consumption" +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&customerId=" +
        customerId +
        "&regionId=" +
        regionId +
        "&type=" +
        type;

      let resp = await api.get(uri, Util.getTokenAPIBackEnd());

      return resp.data;
    } catch (error) {
      return [];
    }
  }

  async findConsumptionTrend(startDate, endDate, customerId, regionId) {
    var retorno = "";
    try {
      var uri =
        "/cloudplus/billing/summary/consumption-trend" +
        "?startDate=" +
        startDate +
        "&&endDate=" +
        endDate +
        "&&customerId=" +
        customerId +
        "&&regionId=" +
        regionId;

      retorno = await api.get(uri, Util.getTokenAPIBackEnd());
      return retorno.data;
    } catch (error) {
      return [];
    }
  }

  async exportConsumptionPdf(
    startDate,
    endDate,
    customerId,
    regionId,
    type,
    description,
  ) {
    var retorno = "";
    try {
      var uri =
        "/cloudplus/billing/detail/consumption/export-pdf" +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&customerId=" +
        customerId +
        "&regionId=" +
        regionId +
        "&type=" +
        type +
        "&description=" +
        description;

      let resp = await api.get(uri, Util.getTokenAPIBackEnd());

      return resp.data;
    } catch (error) {
      return "";
    }
  }

  async exportConsumptionExcel(
    startDate,
    endDate,
    customerId,
    regionId,
    type,
    description,
  ) {
    var retorno = "";
    try {
      var uri =
        "/cloudplus/billing/detail/consumption/export-excel" +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&customerId=" +
        customerId +
        "&regionId=" +
        regionId +
        "&type=" +
        type +
        "&description=" +
        description;

      let resp = await api.get(uri, {
        ...Util.getTokenAPIBackEnd(),
        responseType: "blob",
      });

      return resp.data;
    } catch (error) {
      return "";
    }
  }

  async getResourcesDeleted(signature) {
    var retorno = "";
    try {
      var uri =
        "/cloudplus/billing/detail/resources-deleted" +
        "?signature=" +
        signature;

      let resp = await api.get(uri, { ...Util.getTokenAPIBackEnd() });

      return resp.data;
    } catch (error) {
      return "";
    }
  }

  async getResourcesOveruse(
    startDate,
    endDate,
    customerId,
    regionId,
    type = "",
  ) {
    var retorno = "";
    try {
      var uri =
        "/cloudplus/billing/detail/resource-overuse" +
        "?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&clientId=" +
        customerId +
        "&regionId=" +
        regionId +
        "&type=" +
        type;

      let resp = await api.get(uri, { ...Util.getTokenAPIBackEnd() });

      return resp.data;
    } catch (error) {
      return "";
    }
  }
}

export default new BillingDetailService();
