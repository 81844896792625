import Util from "../utils/Util";
import { api } from "./api";

class Instance {
  async fetchDetailedGroupedInstances(
    customerId,
    page,
    pageSize = 5,
    regionId = 4
  ) {
    try {
      var uri =
        "/cloudplus/openstack-extractor/instances" +
        "?customerId=" +
        customerId +
        "&regionId=" +
        regionId +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize;

      let resp = await api.get(uri, Util.getTokenAPIBackEnd());

      return resp.data;
    } catch (error) {
      return [];
    }
  }

  async exportDetailedGroupedInstances(customerId, regionId = 4) {
    try {
      var uri =
        "/cloudplus/openstack-extractor/export" +
        "?customerId=" +
        customerId +
        "&regionId=" +
        regionId;
      let resp = await api.get(uri, Util.getTokenAPIBackEnd());

      return resp.data;
    } catch (error) {
      return [];
    }
  }

  async exportDetailedGroupedInstancesExcel(customerId, regionId = 4) {
    try {
      var uri =
        "/cloudplus/openstack-extractor/export-excel" +
        "?customerId=" +
        customerId +
        "&regionId=" +
        regionId;
      let resp = await api.get(uri, {
        ...Util.getTokenAPIBackEnd(),
        responseType: "blob",
      });

      return resp.data;
    } catch (error) {
      return [];
    }
  }
}

export default new Instance();
