import { Dialog } from "primereact/dialog";
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import Util from "../../utils/Util";

function DialogDetalhesConsumoGeral(props) {

  return (
    <Dialog header={
      "Detalhes do dia " + Util.formartarDataPadrao(props.data.date) 
       +": " + String(props.data.assetName === 'Não se aplica' ? props.data.assetId : props.data.assetName)
      }
      footer={"Total: " + Util.formatNumber(props.data.amount)}
      visible={props.visible} style={{ width: '70vw' }} onHide={() => props.onHide()}>

      <div>
        <div className="card">
          <DataTable value={Array(props.data)} responsiveLayout="scroll">
            <Column field="status_disco" header="Status do disco"></Column>
            <Column field="local_disco_anexado" header="Local anexado"></Column>
            <Column field="vcpu" header="vcpu"></Column>
            <Column field="memoria" header="Memória"></Column>
            <Column field="imagem" header="Imagem"></Column>
            <Column field="disk_size" header="Tamanho do disco"></Column>
            <Column field="disco_raiz" header="Disco raiz"></Column>
            <Column field="disk_type" header="Tipo do disco"></Column>
            <Column field="recurso" header="Recurso"></Column>
            <Column field="preco_unitario" header="Preço unitário"></Column>
            <Column field="quantity" header="Uso(Horas)"></Column>
          </DataTable>
        </div>

      </div>
    </Dialog>
  )
}

export default DialogDetalhesConsumoGeral;