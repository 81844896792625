//import ncrypt from 'ncrypt-js';
import crypto from "crypto-browserify";

const SECRET_KEY = "Dashboard Algar telecom 2022. @@_@@.";
const ALGORITMO = "aes-256-ctr";

class EncryptData {
  encrypt(text) {
    var cipher = crypto.createCipher(ALGORITMO, SECRET_KEY);
    try {
        var crypted = cipher.update(text, 'utf8', 'hex');
        crypted += cipher.final('hex');
    } catch (e) {
        return;
    }
    return crypted;
  }

  decrypt(text) {
    var decipher = crypto.createCipher(ALGORITMO, SECRET_KEY);
    try {
        var dec = decipher.update(text, 'hex', 'utf8');
        dec += decipher.final('utf8');
    } catch (e) {
        return;
    }
    return dec;
}
}

export default new EncryptData();