import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { TabView, TabPanel } from "primereact/tabview";
import Utils from "../../utils/Util.js";
import credit from "../../assets/credit-card.png";
import flavorIcon from "../../assets/flavor.png";
import IpIcon from "../../assets/ip.png";
import balancerIcon from "../../assets/balancer.png";
import networkIcon from "../../assets/network.png";
import volumeIcon from "../../assets/volume.png";
import { Tooltip } from "primereact/tooltip";
import "./styles.css";

export default function ViewStateResources({
  dataResources,
  resourcesDeleted,
  resourcesOveruse,
  loadingResourcesOveruse,
  loadingResourcesDeleted,
}) {
  const [expandedRows, setExpandedRows] = useState([]);
  const dt = useRef(null);
  const dtResourcesRemoved = useRef(null);

  const contratedValue =
    dataResources != undefined ? dataResources.recursosContratado / 31 : 0;

  const consumedValue =
    dataResources != undefined
      ? dataResources.resources[dataResources.resources.length - 1].used
      : 0;

  const statusOrderBodyTemplate = (rowData) => {
    return (
      <Tag
        severity={getOrderSeverity(rowData)}
        value={rowData.status.toUpperCase()}
      ></Tag>
    );
  };

  const searchBodyTemplate = () => {
    return <Button icon="pi pi-search" />;
  };

  const getProductSeverity = (product) => {
    switch (product.status) {
      case "changed":
        return "warning";

      case "deleted":
        return "danger";

      default:
        return "success";
    }
  };

  const getOrderSeverity = (order) => {
    switch (true) {
      case order.status === "DELETED" || order.status === "DOWN":
        return "danger";

      case order.status.toUpperCase() === "AVAILABLE":
        return "secondary";

      default:
        return "success";
    }
  };

  const allowExpansion = (rowData) => {
    return rowData.data != null;
  };

  const dateBodyTemplateCreatedAt = (rowData) => {
    return Utils.formartarDataPadrao(
      Utils.formatarDataSemHora(rowData.createdAt),
    );
  };

  const dateBodyTemplateDeletedAt = (rowData) => {
    return Utils.formartarDataPadrao(
      Utils.formatarDataSemHora(rowData.endDate),
    );
  };

  const bodyTemplateRam = (rowData) => {
    return rowData.ram / 1024;
  };

  const dateBodyTemplateName = (rowData) => {
    return rowData.name === "" ? rowData.originId : rowData.name;
  };

  const Header = ({ title, type, contratingValue, consumedValue }) => {
    let overuse = 0;

    if (type === "overuse")
      overuse =
        consumedValue - contratingValue > 0
          ? consumedValue - contratingValue
          : 0;

    const price =
      type === "contrating"
        ? contratingValue.toFixed(2)
        : consumedValue.toFixed(2);

    return (
      <div className="flex ml-8 container-header">
        <div className="flex flex-column container-text">
          <span>{title}</span>
          <span>
            {type === "overuse" ? (
              <span className={overuse <= 0 ? "not-overuse" : "overuse"}>
                {overuse.toFixed(2)}
              </span>
            ) : (
              price.replace(".", ",")
            )}
          </span>
        </div>
        <img width={18} height={18} src={credit} alt="Ícone de créditos" />
      </div>
    );
  };

  const imageBodyTemplate = (rowData) => {
    const checkIfSnapshotExist =
      rowData.data?.snapshot_id != null && rowData.data?.snapshot != null;

    const icon = {
      compute: flavorIcon,
      volume: volumeIcon,
      floatingip: IpIcon,
      loadbalancer: balancerIcon,
      network: networkIcon,
    };

    const typeName = {
      volume: checkIfSnapshotExist ? "Volume / Snapshot" : "Volume",
      compute: "Instância",
      floatingip: "IP",
      loadbalancer: "Balanceador",
    };

    return (
      <div className="flex flex-column type-column">
        <img src={icon[rowData.type]} alt={rowData.image} width="28px" />
        <span>{typeName[rowData.type]}</span>
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    const TagCreateAndDelete = (props) => {
      return (
        <Tag
          value={rowData.totalBeforeUpdate.toFixed(2).replace(".", ",")}
          severity={getProductSeverity(rowData)}
        >
          <i
            className={
              props.status != "DELETED" ? "pi pi-arrow-up" : "pi pi-arrow-down"
            }
            style={{ fontSize: "1rem" }}
          ></i>
        </Tag>
      );
    };

    const TagChangePrice = ({ row }) => {
      return (
        <div className="flex tag-change-price">
          <Tag
            value={row.totalBeforeUpdate.toFixed(2).replace(".", ",")}
            severity="warning"
          ></Tag>
          <i className="pi pi-sort-alt" style={{ color: "#708090" }}></i>
          <Tag
            value={row.totalAfterUpdate.toFixed(4).replace(".", ",")}
            severity="success"
          ></Tag>
        </div>
      );
    };

    return (
      <div className="tag-state">
        {rowData.totalAfterUpdate != null ? (
          <TagChangePrice row={rowData} />
        ) : (
          <TagCreateAndDelete status={rowData.status} />
        )}
      </div>
    );
  };

  const ExpansionTemplateNetwork = (props) => {
    const Template = (data) => {
      return (
        <div className="p-3">
          <h5>Descrição: {data.name}</h5>
          <DataTable value={[data.data]}>
            <Column field="provider:network_type" header="Provedor"></Column>
            <Column
              field="status"
              header="Status"
              body={statusOrderBodyTemplate(data)}
            ></Column>
          </DataTable>
        </div>
      );
    };

    return <Template {...(props.data.resourceInfoBefore ?? props.data)} />;
  };

  const ExpansionTemplateVolume = (props) => {
    const Template = (data) => {
      return (
        <>
          <div className="p-3 container-expansion-volume">
            {data.data.snapshot != null ? (
              <>
                <h5>Snapshot</h5>
                <DataTable value={[data.data.snapshot]}>
                  <Column header="Nome" field="name"></Column>
                  <Column header="Tamanho (GB)" field="size"></Column>
                  <Column
                    header="Status"
                    field="status"
                    body={statusOrderBodyTemplate(data.data.snapshot)}
                  ></Column>
                </DataTable>

                <Tooltip target=".tooltip" content="Originado do volume " />
                <i
                  className="tooltip pi pi-arrow-right rotate-90 "
                  style={{ cursor: "pointer" }}
                  tooltipOptions={{ position: "top" }}
                />
              </>
            ) : (
              ""
            )}

            <DataTable value={[data.data]}>
              <Column
                header="So"
                body={(e) => templateVolumeMetadata(e, "os_type")}
              ></Column>
              <Column
                header="Imagem"
                body={(e) => templateVolumeMetadata(e, "image_name")}
              ></Column>
              <Column
                header="Tamanho (GB)"
                body={(rowData) => rowData?.size ?? <span>Não informado</span>}
              ></Column>
              <Column
                header="Tipo"
                body={(rowData) =>
                  rowData?.volume_type ?? <span>Não informado</span>
                }
              ></Column>

              <Column
                header="Status"
                body={statusOrderBodyTemplate(data)}
              ></Column>
            </DataTable>
          </div>
        </>
      );
    };

    return (
      <>
        <Template
          {...(props.data.resourceInfoBefore ?? props.data)}
          update="before"
        />
        {props.data.resourceInfoAfter ? (
          <Template
            {...(props.data.resourceInfoAfter ?? props.data)}
            update="after"
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  const ExpansionTemplateIp = (props) => {
    return (
      <div className="p-3">
        <h5>Descrição: {props.name}</h5>
        <DataTable value={[props.data.data]}>
          <Column field="floating_ip_address" header="Endereço"></Column>
          <Column
            field="status"
            header="Status"
            body={statusOrderBodyTemplate(props.data)}
          ></Column>
        </DataTable>
      </div>
    );
  };

  const ExpansionTemplateInstance = (props) => {
    const Template = (data) => {
      return (
        <div className="p-3">
          <h5>
            {data.update === "before"
              ? "Criado em " +
                Utils.formartarDataPadrao(
                  Utils.formatarDataSemHora(data.createdAt),
                )
              : "Modificado em " +
                Utils.formartarDataPadrao(
                  Utils.formatarDataSemHora(data.updatedAt),
                )}
          </h5>
          <DataTable value={[data.data.flavor]}>
            <Column field="name" header="Flavor"></Column>
            <Column field="vcpus" header="vCPUs"></Column>
            <Column field="ram" body={bodyTemplateRam} header="Ram"></Column>
            <Column
              field="status"
              header="Status"
              body={statusOrderBodyTemplate(data)}
            ></Column>
          </DataTable>
        </div>
      );
    };

    return (
      <>
        <Template
          {...(props.data.resourceInfoBefore ?? props.data)}
          update="before"
        />
        {props.data.resourceInfoAfter ? (
          <Template
            {...(props.data.resourceInfoAfter ?? props.data)}
            update="after"
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  const ExpansionTemplateLoadBalancer = (props) => {
    const Template = (data) => {
      return (
        <div className="p-3">
          <h5>
            {data.update === "before"
              ? "Criado em " +
                Utils.formartarDataPadrao(
                  Utils.formatarDataSemHora(data.createdAt),
                )
              : "Modificado em " +
                Utils.formartarDataPadrao(
                  Utils.formatarDataSemHora(data.updatedAt),
                )}
          </h5>

          <DataTable value={[data.data.vip]}>
            <Column field="ip_address" header="IP"></Column>
            <Column
              field="status"
              header="Status"
              body={statusOrderBodyTemplate(data)}
            ></Column>
          </DataTable>
        </div>
      );
    };

    return (
      <>
        <Template
          {...(props.data.resourceInfoBefore ?? props.data)}
          update="before"
        />
      </>
    );
  };

  const rowExpansionTemplate = (rowData) => {
    const templates = {
      volume: <ExpansionTemplateVolume data={rowData} />,
      floatingip: <ExpansionTemplateIp data={rowData.resourceInfoBefore} />,
      compute: <ExpansionTemplateInstance data={rowData} />,
      loadbalancer: <ExpansionTemplateLoadBalancer data={rowData} />,
      network: <ExpansionTemplateNetwork data={rowData} />,
    };

    return templates[rowData.type];
  };

  const templateVolumeMetadata = (data, column) => {
    return data?.metadata != null && data?.metadata[column] != null
      ? data.metadata[column][0].toUpperCase() + data.metadata[column].slice(1)
      : "Não informado";
  };

  const cols = [
    { field: "name", header: "Nome" },
    { field: "createdAt", header: "Data de Criação" },
    { field: "updatedAt", header: "Data de Modificação" },
    { field: "endDate", header: "Remoção" },
    { field: "type", header: "Recurso" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const formatDateExport = (resources) => {
    if (resources != null) {
      return resources.map((m) => {
        m.createdAt = Utils.formartarDataPadrao(
          Utils.formatarDataSemHora(m.createdAt),
        );
        m.updatedAt = Utils.formartarDataPadrao(
          Utils.formatarDataSemHora(m.updatedAt),
        );
        m.endDate =
          m.endDate != null
            ? Utils.formartarDataPadrao(Utils.formatarDataSemHora(m.endDate))
            : "-";

        return m;
      });
    }
  };

  const exportExcel = (nameFile, resources) => {
    import("xlsx").then((xlsx) => {
      let resourcesClone = JSON.parse(JSON.stringify(resources));
      resourcesClone = formatDateExport(resourcesClone);

      const worksheet = xlsx.utils.json_to_sheet(resourcesClone);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, nameFile);
    });
  };

  const exportPdf = (nameFile, resources, title) => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.text(
          70,
          10,
          title +
            " - " +
            Utils.formartarDataPadrao(
              Utils.formatarDataSemHora(new Date()).toString(),
            ),
        );
        const types = [...new Set(resources.map((m) => m.type))];
        let resourcesClone = JSON.parse(JSON.stringify(resources));
        resourcesClone = formatDateExport(resourcesClone);

        let filter;

        for (let t of types) {
          filter = resourcesClone.filter((f) => f.type === t);

          for (let f of filter) {
            if (f.name === "") {
              f.name = f.originId;
            }
          }
          doc.autoTable(exportColumns, filter);
        }
        doc.save(nameFile + ".pdf");
      });
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date() + EXCEL_EXTENSION,
        );
      }
    });
  };

  const exportItems = (type, title) => {
    if (resourcesOveruse != null && resourcesDeleted != null) {
      let resources = type === "overuse" ? resourcesOveruse : resourcesDeleted;

      if (resources != "") {
        resources =
          type === "overuse"
            ? resources.map((m) => {
                const {
                  totalBeforeUpdate,
                  totalAfterUpdate,
                  resourceInfoBefore,
                  resourceInfoAfter,
                  endDate,
                  ...rest
                } = m;

                return rest;
              })
            : resources.map((m) => {
                const { id, amount, data, projectId, ...rest } = m;

                return rest;
              });
      }

      return [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: (e) => {
            exportPdf("", resources, title);
          },
        },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: (e) => {
            exportExcel("", resources);
          },
        },
      ];
    }
  };

  return (
    <>
      <div className="container-modal-resources">
        <TabView className="tabview">
          <TabPanel header="Sobreuso" leftIcon="pi pi-chart-line mr-2">
            <div className="flex flex-column mt-5 ">
              <div className="flex header-price">
                <Header
                  title="Contratado diário"
                  type="contrating"
                  contratingValue={contratedValue}
                />
                <Header
                  title="Consumido"
                  type="consumed"
                  consumedValue={consumedValue}
                />
                <Header
                  title="Sobreuso"
                  type="overuse"
                  contratingValue={contratedValue}
                  consumedValue={consumedValue}
                />
              </div>
            </div>

            <div className="card mt-5 table-resources">
              <DataTable
                ref={dt}
                value={resourcesOveruse}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                loading={loadingResourcesOveruse}
                emptyMessage="Não há sobreuso"
                dataKey="originId"
                rows={4}
                paginator
              >
                <Column expander={allowExpansion} style={{ width: "5rem" }} />
                <Column
                  header=""
                  body={imageBodyTemplate}
                  style={{ width: "100px" }}
                />
                <Column
                  field="name"
                  body={dateBodyTemplateName}
                  header="Recurso"
                />
                <Column field="createdAt" header="Criação" />
                <Column field="updatedAt" header="Modificação" />
                <Column
                  field="status"
                  header="Créditos"
                  body={statusBodyTemplate}
                />
              </DataTable>
              <SplitButton
                label="Exportar"
                className="btn-export"
                icon="pi pi-download"
                model={exportItems("overuse", "Recursos em Sobreuso")}
              />
            </div>
          </TabPanel>
          <TabPanel header="Recursos Deletados" leftIcon="pi pi-trash mr-2">
            <div className="card mt-5 table-resources mt-5">
              <DataTable
                useRef={dtResourcesRemoved}
                expandedRows={expandedRows}
                value={resourcesDeleted}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                emptyMessage="Não há recursos deletados"
                dataKey="originId"
                loading={loadingResourcesDeleted}
                rows={4}
                paginator
              >
                <Column expander={allowExpansion} style={{ width: "5rem" }} />
                <Column
                  header=""
                  body={imageBodyTemplate}
                  style={{ width: "1px" }}
                />
                <Column
                  field="name"
                  body={dateBodyTemplateName}
                  header="Recurso"
                />
                <Column
                  field="createdAt"
                  body={dateBodyTemplateCreatedAt}
                  header="Criação"
                />
                <Column
                  field="deletedAt"
                  body={dateBodyTemplateDeletedAt}
                  header="Remoção"
                />
              </DataTable>
              <SplitButton
                label="Exportar"
                className="btn-export"
                icon="pi pi-download"
                model={exportItems("deleted", "Recursos Deletados")}
              />
            </div>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}
