import React, { useRef, useState, useEffect } from 'react'
import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';
import MessageNotification from '../MessageNotification';
import { Divider } from 'primereact/divider';
import { SelectButton } from 'primereact/selectbutton';
import loadingIcon from '../../assets/algar-loading.gif'
import NotificationService from '../../services/NotificationService';
import Util from '../../utils/Util';
import ReactHtmlParser from 'react-html-parser'
import { DataScroller } from 'primereact/datascroller';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import IconNoMessage from '../../assets/Notifications/no-message-notification.png'
import "core-js";
import './styles.css'

function Notifications() {

  const op = useRef(null)
  const options = ['Lidas', 'Não lidas']
  const [visualization, setVisualization] = useState(options[1])
  const [loading, setLoading] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [qtyNotifications, setQtyNotifications] = useState()
  const [loadingMore, setLoadingMore] = useState(false)

  const ds = useRef(null)
  const pageSize = useRef(0)
  const page = useRef(0)
  const pageMaxStartPagination = 2

  useEffect(() => {
    setLoading(true)
    findNotifications()
    setLoading(false)
  }, [visualization])


  async function findNotifications() {

    const customerId = window.localStorage.getItem("client_id")

    const response = await NotificationService.findNotifications(Util.typeMessage(visualization), customerId)
    const groupDate = Util.groupBy("createdAt")
    pageSize.current = response.totalPages
    page.current = 0

    setQtyNotifications(response.numberOfElements)
    setNotifications(Object.entries(groupDate(response.content)))

  }


  const Loading = () => {
    return (
      <div className='loading'>
        <img src={loadingIcon} width="80px" height="auto" />
      </div>
    )
  }

  const handleClickMessage = async (message) => {

    if (visualization === options[1]) {
      await NotificationService.markRead(message.id)
    }
  }

  const loadData = async () => {
    setLoadingMore(true)

    const customerId = window.localStorage.getItem("client_id")
    const response = await NotificationService.findNotifications(Util.typeMessage(visualization), customerId, page.current)

    pageSize.current = response.totalPages
    page.current++


    const groupByDate = Util.groupBy("createdAt")
    setNotifications([...notifications, ...Object.entries(groupByDate(response.content))])
    setLoadingMore(false)
  }

  const footer =
    <Button type="text"
      style={pageSize.current < pageMaxStartPagination ? { display: 'none' } : {}}
      disabled={
        pageSize.current >= pageMaxStartPagination && pageSize.current > page.current
          ? false
          : true}
      icon="pi pi-angle-double-down"
      onClick={() => loadData()} ></Button>

  const loadingButton = <ProgressSpinner strokeWidth={2} style={{ width: '30px', height: '30px' }} />

  const itemTemplate = (value, index) => {
    return (
      <>
        <Divider align="center" key={index}>
          <span className="p-tag">{
            Util.formartarDataPadrao(Util.formatarDataSemHora(value[0]))
          }</span>
        </Divider>
        {
          value[1].map((i) => {
            return (
              <MessageNotification
                onTabOpen={() => handleClickMessage(i)}
                severity={i.tipo.toLowerCase()}
                title={i.descricao}
                keyId={i.id}>
                {ReactHtmlParser(i.descricaoDetalhada)}
              </MessageNotification>
            )
          })
        }
      </>

    )
  }

  return (
    <>
      <div className='notifications'>
        <i className={qtyNotifications > 0 ? 'pi pi-bell p-overlay-badge shake' : 'pi pi-bell p-overlay-badge'} onClick={(e) => op.current.toggle(e)}>
          <Badge value={
            String(qtyNotifications ?? 0).concat(page.current < pageSize.current && pageSize.current >= pageMaxStartPagination ? '+' : '')
          }></Badge>
        </i>
        <OverlayPanel className='overlay-panel' ref={op} >
          <div className='overlay-header'>
            <h3>Notificações</h3>
            <Divider />
            <SelectButton
              value={visualization}
              onChange={(e) => setVisualization(e.value === null ? visualization : e.value)}
              options={options}
            />
          </div>

          <div className='overlay-messages'>
            {loading
              ? <Loading />
              :
              <div className='data-scroller'>
                <DataScroller ref={ds} value={notifications} itemTemplate={itemTemplate} rows={Infinity}
                  loader footer={loadingMore ? loadingButton : footer} emptyMessage={
                    <div className='empty-message' key={0}>
                      <img src={IconNoMessage} alt="Imagem com sino amarelo informando que não notificações" />
                      <br />
                      <span>Não há notificações</span>
                    </div>
                  } />
              </div>
            }
          </div>
        </OverlayPanel>
      </div>
    </>
  );

}

export default Notifications
