import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import DropdownOption from "../../Components/Dropdown";
import React, { useEffect, useRef, useState } from "react";
import BillingDetailService from "../../services/BillingDetailService";
import Util from "../../utils/Util";
import util from "../../utils/Util";
import "./style.css";
import DialogDetalhesConsumoGeral from "../../Components/DialogDetalhesConsumoGeral";
import { FilterMatchMode } from "primereact/api";
import Export from "../../utils/Export";
import regionService from "../../services/RegionService";
import DialogSnapshot from "../../Components/DialogSnapshot";
import SnapshotService from "../../services/SnapshotService";

function Instancias() {
  const day_start_current_billing_month = 15;

  const [dataFim, setDataFim] = useState(new Date());
  const [dataInicio, setDataInicio] = useState(
    util.getStartDate(day_start_current_billing_month, dataFim),
  );
  const [loading, setLoading] = useState(false);
  const [consumo, setConsumo] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [regiao, setRegiao] = useState({ id: 0 });
  const [tipo, setTipo] = useState(Util.getInstances()[0]);
  const [titulo, setTitulo] = useState(Util.getInstances()[0].nome);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogSnapshots, setOpenDialogSnapshots] = useState(false);
  const [objdetails, setObjDetails] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [descriptionFilterDatatable, setDescriptionFilterDatatable] =
    useState("");
  const [regions, setRegions] = useState([]);

  const minDate = new Date("2022-06-15");

  useEffect(() => {
    async function fetchRegions() {
      let regions = await regionService.findAllCloudOpenStack();
      regions.push({ id: 0, nome: "Todos", uf: "" });
      setRegions(regions);
    }
    async function getInstances() {
      fetchConsumption();
      fetchRegions();
    }
    getInstances();
  }, []);

  async function fetchConsumption() {
    setLoading(true);

    let response = {};

    if (tipo.nome === "Snapshots") {
      response = await SnapshotService.fetchSnapshots(
        window.localStorage.getItem("client_id"),
        Util.formatarDataSemHora(dataInicio),
        Util.formatarDataSemHora(dataFim),
        regiao.id,
      );
    } else {
      response = await BillingDetailService.fetchInstances(
        window.localStorage.getItem("client_id"),
        Util.formatarDataSemHora(dataInicio),
        Util.formatarDataSemHora(dataFim),
        regiao.id,
        tipo.nome_origem,
      );
    }
    setConsumo(response);
    setLoading(false);
  }

  const rowExpansionTemplate = (data) => {
    return (
      <div className="orders-subtable">
        <h5>Orders for {data.name}</h5>
        <DataTable value={data.itens} responsiveLayout="scroll">
          <Column field="nome" header="Id" sortable></Column>
          <Column field="tipo" header="Customer" sortable></Column>
          <Column field="valor" header="Date" sortable></Column>
        </DataTable>
      </div>
    );
  };

  const matchModes = [
    { label: "Começa com", value: FilterMatchMode.STARTS_WITH },
    { label: "Contém", value: FilterMatchMode.CONTAINS },
    { label: "Igual", value: FilterMatchMode.EQUALS },
    { label: "Não Contém", value: FilterMatchMode.NOT_CONTAINS },
  ];

  const statuses = ["Uberlândia", "São Paulo"];

  const statusItemTemplate = (option) => {
    return <span className={`customer-badge Região-${option}`}>{option}</span>;
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span className={`customer-badge status-${rowData.region}`}>
        {rowData.region}
      </span>
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Selecione uma região"
        className="p-column-filter"
        showClear
      />
    );
  };

  const filterApplyTemplate = (options) => {
    return (
      <Button type="button" onClick={(e) => filterApplyButtonClick(options)}>
        <span>Aplicar</span>
      </Button>
    );
  };

  const filterApplyButtonClick = (options) => {
    options.filterApplyCallback();
    console.log(
      "filterApplyButtonClick - ",
      options.filterModel.constraints !== undefined &&
        options.filterModel.constraints.length > 0,
    );
    if (
      options.filterModel.constraints !== undefined &&
      options.filterModel.constraints.length > 0
    )
      setDescriptionFilterDatatable(options.filterModel.constraints[0].value);
    console.log("filterApplyButtonClick - " + descriptionFilterDatatable);
  };

  const filterClearTemplate = (options) => {
    return (
      <Button
        type="button"
        onClick={options.filterClearCallback}
        className="p-button-secondary"
      >
        <span>Limpar</span>
      </Button>
    );
  };

  function onRowExpand(event) {
    alert("onRowExpand");
  }

  function onRowCollapse(event) {
    alert("onRowCollapse");
  }

  function valorTotalBodyTemplate(rowData, value) {
    return Util.formatNumber(rowData ? rowData.amount : value);
  }

  function dataFormatadaBodyTemplate(rowData) {
    return Util.formartarDataPadrao(Util.formatarDataSemHora(rowData.date));
  }

  function btnBodyTemplate(rowData) {
    return (
      <i
        className="pi pi-info-circle"
        onClick={() => {
          tipo.nome === "Snapshots"
            ? setOpenDialogSnapshots(!openDialogSnapshots)
            : setOpenDialog(!openDialog);

          setObjDetails({ ...rowData, tipo: tipo });
        }}
        style={{ fontSize: "1.5em" }}
      />
    );
  }

  async function exportConsumptionPdf() {
    setLoadingExport(true);
    let report = await BillingDetailService.exportConsumptionPdf(
      Util.formatarDataSemHora(dataInicio),
      Util.formatarDataSemHora(dataFim),
      window.localStorage.getItem("client_id"),
      regiao.id,
      tipo.nome_origem,
      descriptionFilterDatatable,
    );
    setLoadingExport(false);
    Export.openNewTab(report);
  }

  async function exportConsumptionExcel() {
    setLoadingExport(true);
    let report = await BillingDetailService.exportConsumptionExcel(
      Util.formatarDataSemHora(dataInicio),
      Util.formatarDataSemHora(dataFim),
      window.localStorage.getItem("client_id"),
      regiao.id,
      tipo.nome_origem,
      descriptionFilterDatatable,
    );
    setLoadingExport(false);
    Export.download(report, "ConsumoGeral.xlsx");
  }

  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      {loadingExport ? (
        <div>
          <ProgressSpinner
            className="p-progress-spinner-color"
            style={{ width: "22px", height: "22px", marginTop: "5px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        </div>
      ) : (
        ""
      )}
      <Button
        type="button"
        icon="pi pi-file-pdf"
        severity="warning"
        rounded
        data-pr-tooltip="PDF"
        onClick={exportConsumptionPdf}
      />
      <Button
        type="button"
        icon="pi pi-file-excel"
        severity="success"
        rounded
        data-pr-tooltip="XLS"
        onClick={exportConsumptionExcel}
      />
    </div>
  );

  const colsExport = [
    { field: "assetId", header: "Id" },
    { field: "assetName", header: "Nome" },
    { field: "assetType", header: "Tipo" },
    { field: "amount", header: "Valor Total" },
    { field: "date", header: "Data" },
    { field: "region", header: "Data" },
  ];

  return (
    <div className="margin-itens">
      <h1 className="mb-2">{titulo}</h1>
      <Panel header="Filtros" toggleable>
        <div className="formgrid grid">
          <div className="field col-12 mb-2 md:col-2">
            <label htmlFor="firstname2">Inicio</label>
            <Calendar
              id="icon"
              value={dataInicio}
              onChange={(e) => setDataInicio(e.value)}
              showIcon
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              minDate={minDate}
            />
            <label
              htmlFor="date"
              className={
                dataInicio === null
                  ? "p-error"
                  : dataInicio > dataFim
                    ? "p-error-date"
                    : "p-message"
              }
            ></label>
          </div>
          <div className="field col-12 mb-2 md:col-2">
            <label htmlFor="lastname2">Fim</label>
            <Calendar
              id="icon"
              value={dataFim}
              onChange={(e) => setDataFim(e.value)}
              showIcon
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              minDate={minDate}
            />
            <label
              htmlFor="date"
              className={dataFim === null ? "p-error" : "p-message"}
            ></label>
          </div>
          <div className="field col-12 mb-2 md:col-2">
            <label htmlFor="lastname2">Região</label>
            <DropdownOption
              optionLabel="nome"
              value={regiao}
              options={regions}
              onChange={(e) => setRegiao(e.value)}
              placeholder="Selecione a Região"
            />
          </div>
          <div className="field col-12 mb-2 md:col-2">
            <label htmlFor="lastname2">Tipo</label>
            <DropdownOption
              optionLabel="nome"
              value={tipo}
              options={Util.getInstances()}
              onChange={(e) => {
                setTipo(e.value);
                setTitulo(e.value.nome);
              }}
              placeholder="Selecione o tipo"
            />
          </div>
          <div className="search-field">
            <Button
              icon="pi pi-search"
              disabled={
                dataFim === null || dataInicio === null || dataInicio > dataFim
              }
              onClick={fetchConsumption}
            />
          </div>
          <div className="field mr-8 m-auto mb-0 font-bold text-xl">
            <label htmlFor="lastname2">
              Total: {Util.formatNumber(consumo.totalUsed ?? 0)}
            </label>
          </div>
        </div>
      </Panel>
      <DataTable
        header={header}
        value={consumo.resources}
        expor
        paginator
        rows={8}
        responsiveLayout="stack"
        breakpoint="960px"
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        loading={loading}
        globalFilterFields={["status"]}
        emptyMessage="Nenhum resultado encontrado"
      >
        <Column field="assetId" header="Id"></Column>
        <Column
          field="assetName"
          filterField="assetName"
          header="Nome"
          filter
          showFilterMatchModes={true}
          showFilterMenuOptions={true}
          filterPlaceholder="Buscar por nome"
          filterClear={filterClearTemplate}
          filterApply={filterApplyTemplate}
          filterMatchModeOptions={matchModes}
        ></Column>
        <Column field="assetType" header="Tipo"></Column>
        <Column
          field="amount"
          header="Valor Total"
          body={valorTotalBodyTemplate}
        ></Column>
        <Column
          field="date"
          header="Data"
          body={dataFormatadaBodyTemplate}
        ></Column>
        <Column
          field="region"
          header="Região"
          showFilterMenu={regiao.id === 0 ? true : false}
          body={statusBodyTemplate}
          filter
          filterElement={statusRowFilterTemplate}
          showFilterMatchModes={false}
          showApplyButton={false}
          showClearButton={false}
          showFilterMenuOptions={false}
        ></Column>
        <Column field="Info" body={btnBodyTemplate}></Column>
      </DataTable>
      {openDialog ? (
        <DialogDetalhesConsumoGeral
          visible={openDialog}
          onHide={() => setOpenDialog(!openDialog)}
          data={objdetails}
        />
      ) : (
        ""
      )}

      {openDialogSnapshots ? (
        <DialogSnapshot
          visible={openDialogSnapshots}
          onHide={() => setOpenDialogSnapshots(!openDialogSnapshots)}
          data={objdetails}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Instancias;
