import "./style.css"
import { Skeleton } from "primereact/skeleton";
import { Card } from "primereact/card";
import credit from '../../assets/credit-card.png'
import Util from "../../utils/Util";
import { Chip } from 'primereact/chip';


export default function CardInstanceAttributes(props) {
  return (
    <div className={props.data.disable ? "card-disabled" : ""}>
      <Card
        className="container-body"
        title={
          <>
            <span>{props.title}</span>
            {props.header
              ?
              <span>{props.header} GB</span>
              :
              ''
            }
          </>
        }
        footer={false ? <Skeleton shape="p-skeleton-none" />
          : <>
            <div className="flex flex-row-reverse container-credit ">
              <img src={credit} alt="Ícone de créditos" style={{ height: '17px' }} />
              <span>{props.data.disable ? <Skeleton width="2rem" height="20px" />
                : Util.formatNumber(props.data.price, 4)}</span>
            </div>
          </>}>
        <div className='container-info'>
          <div>
            <img src={props.data.icon} alt="Ícone de computador com engrenagens" />
          </div>
          <span>{props.data.name}</span>
          <div className="flex flex-column info-fields">
            {props.data.systemname && props.data.vcpu
              ?
               <div className="info">
                <Chip label={`${props.data?.systemname}`} />
                <Chip label={`vCPUs: ${props.data?.vcpu}`}  />
                <Chip label={`Ram: ${props.data?.ram} GB`}  />
               </div>
              : ''
            }
          </div>
        </div>
      </Card>
    </div>
  )
}
