import React from "react";
import compute from "../../assets/flavor.png";
import volume from "../../assets/volume.png";
import Ip from "../../assets/ip.png";
import { Carousel } from "primereact/carousel";
import CardInstanceAttributes from "../CardInstanceAttributes";
import "./style.css";
import Util from "../../utils/Util";

function DialogInstance(props) {
  const Template = (props) => {
    const attributes = {
      name: props.renderAlone ? props.data?.diskType : props.diskType,
      price: props.renderAlone ? props.data?.diskPrice : props.diskPrice,
      icon: volume,
    };

    return (
      <CardInstanceAttributes
        data={attributes}
        header={props.renderAlone ? props.data?.diskSize : props?.diskSize}
        title="Volume"
      />
    );
  };

  return (
    <div className="grid grid-cols-3 gap-3 p-dialog-container p-dialog-container-gap">
      <CardInstanceAttributes
        data={{
          name: props.data.flavor.flavorName,
          price: props.data.flavor.flavorPrice,
          systemname: props.data.flavor.systemName,
          vcpu: props.data.flavor.vcpu,
          ram: props.data.flavor.ram,
          icon: compute,
        }}
        title="Flavor"
      />
      {props.data.volumes.length > 1 ? (
        <Carousel
          value={props.data.volumes}
          numVisible={1}
          numScroll={1}
          orientation="horizontal"
          itemTemplate={Template}
          containerClassName="containerCarousel"
          style={{ maxWidth: "30vw", minWidth: "30vw" }}
        />
      ) : (
        <Template renderAlone={true} data={props.data.volumes[0]} />
      )}

      <CardInstanceAttributes
        data={{
          name:
            props.data.ips[0] === undefined
              ? "Não possui IP associado."
              : props.data.ips[0].floatingIps,
          price:
            props.data.ips[0] === undefined ? 0 : props.data.ips[0].priceIP,
          icon: Ip,
          disable: props.data.ips[0] === undefined ? true : undefined,
        }}
        title="IP Persistente"
      />

      <hr />
      <div className="container-footer-dialog">
        <span>Total consumido: </span>
        <span>{Util.formatNumber(props.data.totalCost, 4)}</span>
      </div>
    </div>
  );
}

export default DialogInstance;
