import { Tag } from 'primereact/tag';
import classnames from 'classnames';
import './style.css'

export default function TagCard(props){
    return ( 
        <Tag value={props.value} severity={props.severity}>
            <i className={classnames('icon', props.primaryIcon)}></i>
            <i className={classnames('icon', props.secundaryIcon)}></i>
        </Tag>
    )
}