import React, { useEffect, useState, useRef, useMemo } from "react";
import { Button } from "primereact/button";
import CardInformation from "../../Components/CardInformation";
import { Skeleton } from "primereact/skeleton";
import DialogInstace from "../../Components/DialogInstance";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataScroller } from "primereact/datascroller";
import instanciaService from "../../services/InstanciaService";
import { ProgressSpinner } from "primereact/progressspinner";
import { Message } from "primereact/message";
import "./style.css";
import { SplitButton } from "primereact/splitbutton";
import { InputText } from "primereact/inputtext";
import Export from "../../utils/Export";
import Util from "../../utils/Util";

function Instancias() {
  const [instances, setInstances] = useState({});
  const [instancesNew, setInstancesNew] = useState([]);
  const [filterInstances, setFilterInstances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pages, setPages] = useState(0);

  const ds = useRef(null);
  const page = useRef(0);
  const [loadingExport, setLoadingExport] = useState(false);

  const totalInstances = useMemo(
    () =>
      instancesNew ? (
        instancesNew.length
      ) : (
        <i className="pi pi-exclamation-triangle"></i>
      ),
    [instancesNew],
  );

  const totalCreditos = useMemo(() => {
    if (instancesNew !== undefined && instancesNew.length > 0) {
      return instancesNew.reduce((acc, val) => acc + val.totalCost, 0);
    }
    return 0;
  }, [instancesNew]);

  async function fetchInstances() {
    setLoading(true);
    try {
      const clientId = localStorage.getItem("client_id");

      const localInstances = JSON.parse(
        localStorage.getItem("instances-" + clientId),
      );

      if (
        localInstances != null &&
        new Date() < new Date(localInstances?.expirationDate)
      ) {
        setInstancesNew(localInstances.data);
      } else {
        const response = await requestFetchInstances(page.current);

        if (response != null) {
          setInstancesNew(response.resources);
          setInstances(response);
        }
      }
    } catch (e) {
      console.error("ERROR: cloudplus/openstack-extractor/instances", e);
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchInstances();
  }, []);

  useEffect(async () => {
    if (JSON.stringify(instances) != "{}") {
      let aux = instancesNew;
      while (page.current < instances.totalPages) {
        try {
          setLoadingMore(true);
          await Promise.all(
            [...Array(10).keys()].map(async (m) => {
              return await requestFetchInstances(++page.current);
            }),
          ).then((values) => {
            values.map((v) => {
              aux = [...aux, ...v.resources];
            });

            setInstancesNew(aux);
          });
        } catch (e) {
          console.error("error", e);
        }
      }
      setLoadingMore(false);

      if (instances.totalLines === aux.length) {
        const localInstances = JSON.parse(localStorage.getItem("instances"));
        const clientId = localStorage.getItem("client_id");

        if (
          localInstances === null ||
          new Date() > new Date(localInstances?.expirationDate)
        ) {
          let expireDate = new Date();

          expireDate.setHours(expireDate.getHours() + 1);

          let dataToCache = {
            data: aux,
            expirationDate: expireDate,
          };

          const local = localStorage.setItem(
            "instances-" + clientId,
            JSON.stringify(dataToCache),
          );
        }
      }
    }
  }, [instances]);

  async function requestFetchInstances(page, pageSize) {
    const fetch = await instanciaService.fetchDetailedGroupedInstances(
      window.localStorage.getItem("client_id"),
      page,
      pageSize,
    );

    console.log(fetch);

    return fetch;
  }

  const itemTemplate = (val, index) => {
    return (
      <>
        <Accordion key={index}>
          <AccordionTab header={val.name}>
            <DialogInstace header="Instância" data={val} />
          </AccordionTab>
        </Accordion>
      </>
    );
  };

  const loadData = async () => {
    page.current++;
    setLoadingMore(true);

    const response = await requestFetchInstances();

    setInstances({
      resources: [...instances.resources, ...response.resources],
      totalLines: response.totalLines,
      totalPages: response.totalPages,
    });

    setLoadingMore(false);
    ds.current.load();
  };

  const itemsExports = [
    {
      label: "PDF",
      icon: "pi pi-file-pdf",
      command: (e) => {
        exportReportInstances("pdf");
      },
    },
    {
      label: "Excel",
      icon: "pi pi-file-excel",
      command: (e) => {
        exportReportInstances("xlsx");
      },
    },
  ];

  const footer = (
    <Button
      type="text"
      disabled={instances.totalPages === page.current ? true : false}
      icon="pi pi-angle-double-down"
      label=""
      onClick={loadData}
    />
  );
  const loadingButton = (
    <ProgressSpinner
      strokeWidth={2}
      style={{ width: "50px", height: "50px" }}
    />
  );

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const stringBlob = event.target.result;
        resolve(stringBlob);
      };

      reader.onerror = function (event) {
        reject(new Error("Erro na leitura do Blob"));
      };

      reader.readAsDataURL(blob);
    });
  }

  async function exportReportInstances(type) {
    let exportStorage;
    let relatorio;
    var blobToString;

    setLoadingExport(true);

    if (typeof window != "undefined") {
      const clientId = localStorage.getItem("client_id");
      exportStorage = sessionStorage.getItem(clientId + `-${type}`);

      if (
        exportStorage != null &&
        new Date(new Date().toISOString().split("T")[0]) >
          new Date(JSON.parse(exportStorage).expirationDate)
      ) {
        sessionStorage.removeItem(clientId);
        exportStorage = null;
      }

      if (exportStorage === null) {
        if (type === "pdf") {
          relatorio = await instanciaService.exportDetailedGroupedInstances(
            window.localStorage.getItem("client_id"),
          );
        } else if (type === "xlsx") {
          relatorio =
            await instanciaService.exportDetailedGroupedInstancesExcel(
              window.localStorage.getItem("client_id"),
            );

          await blobToBase64(relatorio)
            .then((stringBlob) => {
              blobToString = stringBlob;
            })
            .catch((error) => {
              console.log("Error", error);
            });
        }

        let dataToCache = {
          data: blobToString != undefined ? blobToString : relatorio,
          expirationDate: new Date().toISOString().split("T")[0],
        };

        sessionStorage.setItem(
          clientId + `-${type}`,
          JSON.stringify(dataToCache),
        );
      }

      type === "pdf"
        ? Export.openNewTab(
            exportStorage === null ? relatorio : JSON.parse(exportStorage).data,
          )
        : exportStorage === null
          ? Export.download(relatorio, "InstânciasDetalhadas.xlsx")
          : Export.downloadBlobFromUrl(
              JSON.parse(exportStorage).data,
              "InstânciasDetalhadas.xlsx",
            );
    }

    setLoadingExport(false);
  }

  const TemplateCardInfo = (props) => {
    return (
      <div className="template-card-info">
        {loading ? (
          <Skeleton shape="p-skeleton-none" />
        ) : (
          <span>{props.total}</span>
        )}
        {loadingMore ? (
          <ProgressSpinner
            style={{ width: "13px", height: "13px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  const onChangeSearch = (e) => {
    const search = e.target.value;

    const filter = instancesNew.filter(
      (f) => f.name.includes(search) || f.flavor.flavorName.includes(search),
    );

    setFilterInstances(filter);
  };

  return (
    <div className="margin-itens container-component">
      <h1 className="mb-2">Instâncias</h1>
      <Message
        severity="info"
        text="Informações disponíveis somente para São Paulo."
      />
      <div className="grid-instances grid grid-cols-2 gap-3  mb-5 ml-2 container-grid">
        <CardInformation
          value={<TemplateCardInfo total={totalInstances} />}
          title="Total de Instâncias"
        />

        <CardInformation
          value={<TemplateCardInfo total={Util.formatNumber(totalCreditos)} />}
          title="Total de Créditos"
        />

        <div className="export-field">
          {loadingExport ? (
            <ProgressSpinner
              className="p-progress-spinner-color"
              style={{ width: "22px", height: "22px", marginTop: "5px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          ) : (
            ""
          )}
          <SplitButton
            label="Exportar"
            className="p-button-outlined-primary ml-2 p-button-outlined  "
            model={itemsExports}
          />
        </div>
      </div>

      <div className="card container-instances">
        <div className="container-search">
          <span className="p-input-icon-left p-search ">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm"
              placeholder="Informe uma instância ou flavor"
              onChange={(e) => onChangeSearch(e)}
              disabled={loadingMore || loading ? true : false}
            />
          </span>
        </div>
        <div className="datascroller-demo">
          <div className="card">
            {loading ? (
              [...Array(5).keys()].map((t) => {
                return (
                  <div key={t}>
                    <Skeleton
                      width="100%"
                      height="50px"
                      className="mb-2"
                    ></Skeleton>
                  </div>
                );
              })
            ) : (
              <DataScroller
                ref={ds}
                value={
                  filterInstances.length > 0 ? filterInstances : instancesNew
                }
                rows={5}
                inline
                scrollHeight="500px"
                itemTemplate={itemTemplate}
                rows={Infinity}
                loader
                footer={loadingMore ? loadingButton : ""}
                emptyMessage="Nenhum resultado encontrado"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instancias;
