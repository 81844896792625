import { api } from "./api.js"
import Util from '../utils/Util'

class Notification {

  async findNotifications(status, customerId, page = 0, pageSize = 20) {
    try {
      const uri = "/cloudplus/notifications"
        + "?status=" + status
        + "&customerId=" + customerId
        + "&size=" + pageSize
        + "&page=" + page


      const response = await api.get(uri, Util.getTokenAPIBackEnd())


      return response.data
    } catch (error) {
      return []
    }

  }

  async markRead(id) {

    try {
      const uri = "/cloudplus/notifications/"
        + id + "/mark-read"

      const response = await api.put(uri, null, Util.getTokenAPIBackEnd())

      return response

    } catch (error) {
      return []
    }
  }

}


export default new Notification()

